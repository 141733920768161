const adjectives = ["cool","ancient","angry","average","bad","big","bitter","black","blue","brave","breezy","bright","brown","calm","chatty","chilly","clever","cold","cowardly","cuddly","curly","curvy","dangerous","dry","dull","empty","evil","fast","fluffy","foolish","fresh","friendly","funny","fuzzy","gentle","giant","good","great","green","grumpy","happy","hard","heavy","helpless","honest","horrible","hot","hungry","itchy","jolly","kind","lazy","light","little","loud","lovely","lucky","massive","mean","mighty","modern","moody","neat","nervous","new","nice","odd","old","orange","ordinary","perfect","pink","plastic","polite","popular","pretty","proud","purple","quick","quiet","rare","red","rotten","rude","selfish","serious","shaggy","sharp","short","shy","silent","silly","slimy","slippery","smart","smooth","soft","sour","spicy","splendid","spotty","stale","strange","strong","stupid","sweet","swift","tall","tame","tasty","tender","terrible","thin","tidy","tiny","tough","tricky","ugly","warm","weak","wet","white","wicked","wise","witty","wonderful","yellow","young"];
const animals = ["ape","baboon","badger","bat","bear","bird","bobcat","bulldog","bullfrog","cat","catfish","cheetah","chicken","chipmunk","cobra","cougar","cow","crab","deer","dingo","dodo","dog","dolphin","donkey","dragon","dragonfly","duck","eagle","earwig","eel","elephant","emu","falcon","fireant","firefox","fish","fly","fox","frog","gecko","goat","goose","grasshopper","horse","hound","husky","impala","insect","jellyfish","kangaroo","ladybug","liger","lion","lionfish","lizard","mayfly","mole","monkey","moose","moth","mouse","mule","newt","octopus","otter","owl","panda","panther","parrot","penguin","pig","puma","pug","quail","rabbit","rat","rattlesnake","robin","seahorse","sheep","shrimp","skunk","sloth","snail","snake","squid","starfish","stingray","swan","termite","tiger","treefrog","turkey","turtle","vampirebat","walrus","warthog","wasp","wolverine","wombat","yak","zebra"];

class AddressFormat {

  static parseHashAddress(hash, abbreviate){

    if(!hash)return null;
    if(hash === '0x7e3dc9f40e7ff9db80c3c7a1847cb95f861b3aef')return abbreviate ? 'MEO' : 'MyEtheroll Official';
    if(hash === '0xf507a9d9af0fc86d4efa040607dd2b653195237f')return abbreviate ? 'μER' : 'Micro Etheroll';
    if(hash === '0x9b142c5b7a64629ccab9872daea2af9f82f64e8d')return abbreviate ? 'μER' : 'Micro Etheroll';
    if(hash === '0x505e0182eedfde30b3ba301572455320e02a9198')return abbreviate ? 'EFf' : 'EtherFLIPfan';
    if(hash === '0xf12cf9f511f8495b0c365d537112721c9e8df2e3')return abbreviate ? 'TLC3' : 'Liquidity Contract v3';
    if(hash === '0xfe42748a47828d8dab9fbc4e50658b0e9fb60837')return abbreviate ? 'ckd' : 'u/ckd001';
    if(hash === '0x41c66af6438a7275986243356255ea198a92d06f')return abbreviate ? 'ckd' : 'u/ckd001';

    let readableString = "";

    const nameParts = this.getNameParts(this.splitHash(hash));

    if(abbreviate){
      readableString += nameParts[0].substr(0,1)+nameParts[1].substr(0,1)+nameParts[2]
    } else {
      readableString += nameParts[0]+"-"+nameParts[1]+"-"+nameParts[2]
    }

    return readableString.charAt(0).toUpperCase() + readableString.slice(1)

  }

  static splitHash(hash){
    const hashParts = hash.match(/.{1,14}/g);
    return [
      Math.abs(Math.sin(parseInt(hashParts[1],16))),
      Math.abs(Math.sin(parseInt(hashParts[2],16))),
      Math.abs(Math.sin(parseInt(hashParts[0],16))),
    ]
  }

  static getNameParts(hashParts){
    return [
      adjectives[Math.floor(hashParts[0] * adjectives.length)],
      animals[Math.floor(hashParts[1] * animals.length)],
      Math.round(hashParts[2] * 100),
    ]
  }

  static getNumerical(hash, base=5){
    return 1+Math.abs(Math.round(Math.sin(parseInt(hash)) * (base - 1)));
  }

}

export default AddressFormat;