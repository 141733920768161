import React from 'react';

import './loader-horizontal.scss';

const LoaderHorizontal = () => {
  return (
    <div className='loader loader-horizontal' />
  )
};

export default LoaderHorizontal;