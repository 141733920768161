import React from "react";
import CardHouseProfit from "./CardHouseProfit";
import CardActivity from "./CardActivity";
import CardYourShare from "./CardYourShare";
import CardYourStack from "./CardYourStack";
import CardRates from "./CardRates";
import {connect} from "react-redux";
import {getBalance} from "../../ducks/status";

import './tab-status.scss';
import Timer from "../util/Timer";
import {loadRateChange} from "../../ducks/rateChange";
import {isProd} from "../../util/misc";
import GamePausedWarning from "./GamePausedWarning";
import DividendCountdown from "./DividendCountdown";
import DividendPayoutWarning from "./DividendPayoutWarning";
import LatestTroll from "../tab-troll-box/LatestTroll";

class TabStatus extends React.Component {

    render() {
        const {loadRateChange} = this.props;
        return (
            <div id='tab-current'>
                <GamePausedWarning/>
                <DividendPayoutWarning/>
                <DividendCountdown/>
                <LatestTroll/>
                <div id='tab-current__cards'>
                    <Timer
                        action={loadRateChange}
                        interval={30}
                        offset={30}
                        instantly={isProd()} // TODO: Remove
                    />
                    <CardHouseProfit/>
                    <CardActivity/>
                    <CardYourShare/>
                    <CardYourStack/>
                    <CardRates/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        houseBalance: getBalance(state)
    }
};

const mapDispatchToProps = {
    loadRateChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabStatus);