import React from 'react';

const Card = ({children, className}) => {
  return (
    <div children={children}
         className={`card ${className}`}
    />
  )
};

export default Card;