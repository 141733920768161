import {getFromLocalStorage} from "../util/persistance";
import {fetchTopPlayers} from "../services/topPlayersAPI";

export const NAME_SPACE = 'top-players';

const LOAD_DATA_PENDING = `${NAME_SPACE}/LOAD_DATA_PENDING`;
const LOAD_DATA_FAIL = `${NAME_SPACE}/LOAD_DATA_FAIL`;
const LOAD_DATA_SUCCESS = `${NAME_SPACE}/LOAD_DATA_SUCCESS`;

const SET_METRIC = `${NAME_SPACE}/SET_METRIC`;
const SET_PERIOD = `${NAME_SPACE}/SET_PERIOD`;

function loadDataPending() {
    return {
        type: LOAD_DATA_PENDING
    }
}

function loadDataFail(error) {
    return {
        type: LOAD_DATA_FAIL,
        error: error
    }
}

function loadDataSuccess(response) {
    return {
        type: LOAD_DATA_SUCCESS,
        players: response.data,
    }
}

export const loadTopPlayers = (metric, period) => dispatch => {
    dispatch(loadDataPending());
    fetchTopPlayers(
        metric,
        period,
    ).then(
        response => dispatch(loadDataSuccess(response)),
        error => dispatch(loadDataFail(error)),
    )
};

export const TOP_PLAYERS_METRICS = {
    PROFIT: 'profit',
    ROLLS: 'rolls',
    WAGERED: 'wagered',
};

export const TOP_PLAYERS_PERIODS = {
    'day': 'Today',
    '24h': 'Last 24 hours',
    'week': 'Last 7 days',
    'month': 'Last 30 days',
    'all-time': 'All Time',
};

const initialState = {
    isLoading: false,
    isDataLoaded: false,
    error: null,
    players: [],
    metric: getFromLocalStorage(`${NAME_SPACE}/metric`) || 'won',
    period: getFromLocalStorage(`${NAME_SPACE}/period`) || '24H',
};

function topPlayersReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_DATA_PENDING:
            return {
                ...state,
                isLoading: true,
            };
        case LOAD_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                error: action.error,
            };
        case LOAD_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                players: [...action.players],
            };
        case SET_METRIC:
            return {
                ...state,
                metric: action.metric,
                isDataLoaded: action.metric === state.metric ? state.isDataLoaded : false,
            };
        case SET_PERIOD:
            return {
                ...state,
                period: action.period,
                isDataLoaded: action.period === state.period ? state.isDataLoaded : false,
            };
        default:
            return state;
    }
}

function getState(state) {
    return state[NAME_SPACE];
}

export function getPlayers(state) {
    return getState(state).players;
}

export function getIsLoading(state) {
    return getState(state).isLoading;
}

export function getIsDataLoaded(state) {
    return getState(state).isDataLoaded;
}

export default topPlayersReducer;