import React from 'react';

import Quarters from "../../data/quarters";
import {getHouseProfitQuarter} from "../../ducks/status";
import {getCurrency} from "../../ducks/currency";
import {getRatio} from "../../ducks/holdings";
import Counter from "../util/Counter";

import './quarter.scss';
import NumberUpIcon from "mdi-react/ArrowUpIcon";
import NumberDownIcon from "mdi-react/ArrowDownIcon";
import {useSelector} from "react-redux";

const Quarter = ({idx, start, end, profits}) => {
  const houseProfits = useSelector(state => getHouseProfitQuarter(state));
  const currency = useSelector(state => getCurrency(state));
  const ratio = useSelector(state => getRatio(state));
  const quarterProfits = idx === 0 ? houseProfits : profits;
  const negativeBalance = Quarters.getNegativeBalance(idx);
  const dividendBase = quarterProfits + negativeBalance;
  return (
    <div className='dividend-quarter'>
      <h2>
        Quarter {Quarters.getAll().length - idx} {idx === 0 && `- current`}
      </h2>
      <h3>
        From {new Date((start) * 1000).toLocaleDateString()} to {new Date((end) * 1000).toLocaleDateString()} {idx === 0 ? 'apx.' : ''}
      </h3>
      <div className='dividend-quarter__data-points'>

        <div className='dividend-quarter__data-points__data-point'>
          <h4>
            House {quarterProfits >= 0 ? 'profit' : 'loss'}
          </h4>
          <div className='dividend-quarter__data-points__data-point__value'>
            {quarterProfits >= 0 ?
              <NumberUpIcon className='icon_positive'/> :
              <NumberDownIcon className='icon_negative'/>
            }
            <Counter
              amount={quarterProfits}
              decimals={2}
              postfix=" ETH"
              absolute={true}
            />
          </div>
          <div className='dividend-quarter__data-points__data-point__sub-value'>
            {quarterProfits >= 0 ?
              <NumberUpIcon className='icon_positive'/> :
              <NumberDownIcon className='icon_negative'/>
            }
            <Counter
              amount={quarterProfits * currency.rate}
              decimals={currency.decimals}
              prefix={currency.prefix}
              postfix={currency.postfix}
              absolute={true}
            />
          </div>
        </div>

        <div className='dividend-quarter__data-points__data-point'>
          <h4>
            Your share {(quarterProfits < 0 || negativeBalance < 0) && `*`}
          </h4>
          <div className='dividend-quarter__data-points__data-point__value'>
            <Counter
              amount={dividendBase < 0 ? 0 : dividendBase * ratio}
              decimals={2}
              postfix=" ETH"
              absolute={true}
            />
          </div>
          <div className='dividend-quarter__data-points__data-point__sub-value'>
            <Counter
              amount={dividendBase < 0 ? 0 : dividendBase * ratio * currency.rate}
              decimals={currency.decimals}
              prefix={currency.prefix}
              postfix={currency.postfix}
              absolute={true}
            />
          </div>
        </div>
      </div>

      {quarterProfits < 0 &&
      <div className="dividend-quarter__asterisk">
        No dividends for this quarter due to negative house profits.
      </div>
      }
      {negativeBalance < 0 && quarterProfits > 0 &&
      <div className="dividend-quarter__asterisk">
        Only profits exceeding the negative balance of <Counter amount={negativeBalance * -1}
                                                                decimals={2}
                                                                postfix=" ETH"/> {idx === 0 ? 'will be' : 'was'} paid
        out as dividends for this quarter.
      </div>
      }

    </div>
  )
};

export default Quarter;