import React from 'react';

import './header.scss';

export const Header = () => {
    return (
        <header>
          <h1>
            MyEtheroll.com
            <span className='version'>
              v3
            </span>
          </h1>
          <h4>
            Dividend calculator, updated in real time
          </h4>
        </header>
    )
};

export default Header;