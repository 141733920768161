import Quarters from "../../data/quarters";
import {round} from "../../util/formatters/numbers";
import {CHART_COLORS} from "./chartColors";
import oldStats from './oldStats.json'
import {isMobilePortrait} from "./chartJsPlugins";

export default class StatsDataFactory {

  static parseData(history, last24hours, houseProfits, dateFrom, dateTo) {

    const labels = [];

    const players = [];
    const playersAvg = [];

    const newPlayers = [];
    const newPlayersAvg = [];

    const bets = [];
    const betsAvg = [];

    const houseProfitChange = [];
    const houseProfitChangeAvg = [];

    const houseProfitEV = [];
    const houseProfitEVAvg = [];

    const houseProfitTotal = [];
    const houseProfitTotalAvg = [];

    const wagered = [];
    const wageredAvg = [];

    const avgBetSizes = [];
    const avgBetSizesAvg = [];

    const avgBetTime = [];
    const avgBetTimeAvg = [];

    const periods = Quarters.getDateMap();
    let lineAtIndex = [];

    for(let i = history.length - 1; i >= 0; i--){

      history[i].total_profit = history[i].balance + history[i].withdrawn_profit - history[i].base_bankroll;
      history[i].profit_change = history[i + 1] ? history[i].total_profit - history[i + 1].total_profit : 0;
      if (!history[i].new_players) history[i].new_players = history[i + 1] ? history[i].total_players - history[i + 1].total_players : history[i].players;
      history[i].avg_bet_size = history[i].eth_wagered / history[i].bets;

      const gasCost = history.length - i >= 142 ? 0.003 : 0.005;
      history[i].ev = (history[i].eth_wagered * 0.01) - (history[i].bets * gasCost);
      history[i].ev += history[i + 1] ? history[i + 1].ev : 0;
      if (history[i].ev < 0) history[i].ev = 0;

      if (history[i].avg_roll_time > 1000) history[i].avg_roll_time *= .01;

    }

    for (let i = dateFrom; i >= dateTo; i--) {

      const isLast24Hours = i === 0;

      labels.push(isLast24Hours ? 'last 24h' : history[i].date.substring(isMobilePortrait() ? 5 : 0, 10));

      players.push(isLast24Hours ? last24hours.players : history[i].players);
      playersAvg.push(this.findAvg(history, i, 'players'));

      newPlayers.push(history[i].new_players);
      newPlayersAvg.push(this.findAvg(history, i, 'new_players')); // TODO: Fix for last 24 hours

      bets.push(isLast24Hours ? last24hours.bets : history[i].bets);
      betsAvg.push(this.findAvg(history, i, 'bets'));

      wagered.push(round(isLast24Hours ? last24hours.eth_wagered : history[i].eth_wagered, 2));
      wageredAvg.push(this.findAvg(history, i, 'eth_wagered'));

      avgBetSizes.push(round(isLast24Hours ? last24hours.eth_wagered / last24hours.bets : history[i].avg_bet_size, 4));
      avgBetSizesAvg.push(this.findAvg(history, i, 'avg_bet_size'));

      avgBetTime.push(round(history[i].avg_roll_time, 4));
      avgBetTimeAvg.push(this.findAvg(history, i, 'avg_roll_time'));

      if (i === 0) {
        houseProfitTotal.push(round(houseProfits, 2));
        houseProfitTotalAvg.push(this.findAvg(history, 1, 'total_profit'));
        houseProfitChange.push(round(history[i].house_profit, 2));
        houseProfitChangeAvg.push(this.findAvg(history, i, 'house_profit'));
        houseProfitEV.push(round(history[i].ev, 2));
        houseProfitEVAvg.push(this.findAvg(history, i, 'ev'));
      } else {
        houseProfitTotal.push(round(history[i].total_profit, 2));
        houseProfitTotalAvg.push(this.findAvg(history, i, 'total_profit'));
        houseProfitChange.push(round(history[i].profit_change, 2));
        houseProfitChangeAvg.push(this.findAvg(history, i, 'profit_change'));
        houseProfitEV.push(round(history[i].ev, 2));
        houseProfitEVAvg.push(this.findAvg(history, i, 'ev'));

        let period = periods[history[i].date.substring(0, 10)];
        if (period) {
          period.index = history.length - i - (history.length - dateFrom);
          lineAtIndex.push(period);
        }

      }

    }

    return {
      labels,
      players,
      playersAvg,
      newPlayers,
      newPlayersAvg,
      bets,
      betsAvg,
      houseProfitChange,
      houseProfitChangeAvg,
      houseProfitEV,
      houseProfitEVAvg,
      houseProfitTotal,
      houseProfitTotalAvg,
      wagered,
      wageredAvg,
      avgBetSizes,
      avgBetSizesAvg,
      avgBetTime,
      avgBetTimeAvg,
      lineAtIndex,
    }

  }


  static createDataSets(history, last24hours, houseProfits, props, dateFrom, dateTo){

    const totalHistory = [...history, ...oldStats];

    const data = this.parseData(totalHistory, last24hours, houseProfits, dateFrom, dateTo);

    const dataSets = [];
    const allDataSets = [];

    const {
      showMovingAverages,
      showAvgBetSize,
      showBetCount,
      showPlayerCount,
      showNewPlayerCount,
      showEthWagered,
      showHouseProfitChange,
      showHouseProfitTotal,
      showHouseProfitEV,
    } = props;

    if (showAvgBetSize) {
      dataSets.push(this.createDataSet('Avg. Bet Size', data.avgBetSizes, 8, 'E'));
      if (showMovingAverages) dataSets.push(this.createDataSet('Moving Bet Size Avg.', data.avgBetSizesAvg, 9, 'E'));
    }

    if (showBetCount) {
      dataSets.push(this.createDataSet('Bets', data.bets, 0, 'D'));
      if (showMovingAverages) dataSets.push(this.createDataSet('Bets Avg.', data.betsAvg, 1, 'D'))
    }

    if (showPlayerCount) {
      dataSets.push(this.createDataSet('Players', data.players, 2, 'C'));
      if (showMovingAverages) dataSets.push(this.createDataSet('Players Avg.', data.playersAvg, 3, 'C'))
    }

    if (showNewPlayerCount) {
      dataSets.push(this.createDataSet('New Players', data.newPlayers, 16, 'C'));
      if (showMovingAverages) dataSets.push(this.createDataSet('New Players Avg.', data.newPlayersAvg, 17, 'C'))
    }

    if (showEthWagered) {
      dataSets.push(this.createDataSet('ETH Wagered', data.wagered, 6, 'A'));
      if (showMovingAverages) dataSets.push(this.createDataSet('ETH Wagered Avg.', data.wageredAvg, 7, 'A'))
    }

    if (showHouseProfitChange) {
      dataSets.push(this.createDataSet('House Profit Change', data.houseProfitChange, 4, 'B'));
      if (showMovingAverages) dataSets.push(this.createDataSet('House Profit Change Avg.', data.houseProfitChangeAvg, 5, 'B'))
    }

    if (showHouseProfitTotal) {
      dataSets.push(this.createDataSet('House Profit Total', data.houseProfitTotal, 12, 'F'));
      if (showMovingAverages) dataSets.push(this.createDataSet('House Profit Total Avg.', data.houseProfitTotalAvg, 13, 'F'))
    }

    if (showHouseProfitEV) {
      dataSets.push(this.createDataSet('House Profit EV', data.houseProfitEV, 14, 'F'));
      if (showMovingAverages) dataSets.push(this.createDataSet('House Profit EV Avg.', data.houseProfitEVAvg, 15, 'F'))
    }

    allDataSets.push(this.createDataSet('Bets', data.bets, 0, 'D'));
    allDataSets.push(this.createDataSet('Players', data.players, 2, 'C'));
    allDataSets.push(this.createDataSet('New Players', data.newPlayers, 16, 'C'));
    allDataSets.push(this.createDataSet('ETH Wagered', data.wagered, 6, 'A'));
    allDataSets.push(this.createDataSet('Avg. Bet Size', data.avgBetSizes, 8, 'E'));
    allDataSets.push(this.createDataSet('House Profit Total', data.houseProfitTotal, 12, 'F'));
    allDataSets.push(this.createDataSet('House Profit EV', data.houseProfitEV, 14, 'F'));
    allDataSets.push(this.createDataSet('House Profit Change', data.houseProfitChange, 4, 'B'));

    return {
      "labels": data.labels,
      "datasets": dataSets,
      "allDataSets": allDataSets,
      "lineAtIndex": data.lineAtIndex
    }
  }

  static findAvg(data, idx, attr) {
    let sum = 0;
    let end = idx + 10;
    if (end > data.length) {
      end -= (end - data.length)
    }
    for (let i = idx; i < end; i++) {
      sum += data[i][attr]
    }
    return round(sum / (end - idx), 2)
  };

  static createDataSet(name, data, colorId, yAxisID) {

    return {
      "label": name,
      "data": data,
      "yAxisID": yAxisID,
      "fill": false,
      "pointRadius": 0,
      "borderWidth": 1.5,
      "pointHitRadius": 1.5,
      "borderColor": CHART_COLORS[colorId] || null,
      "lineTension": 0.1
    }

  }

  static createOptions(props, lineAtIndex){
    const {
      showAvgBetSize,
      showBetCount,
      showPlayerCount,
      showNewPlayerCount,
      showEthWagered,
      showHouseProfitChange,
      showHouseProfitTotal,
      showHouseProfitEV,
      showPeriodMarkers,
    } = props;
    return {
      tooltips: {
        mode: 'label'
        , displayColors: true
        , multiKeyBackground: "#000"
        , enabled: true
        , intersect: false
      },
      legend: {
        display: false
      },
      scales: {
        xAxes: [
          {
            ticks: {display: true}
            , gridLines: {
              color: "#333333"
              , display: true
            }
          }
        ],
        yAxes: [
          {
            id: 'B',
            display: showHouseProfitChange && !isMobilePortrait(),
            gridLines: {
              color: "#262626"
            }
          },
          {
            id: 'F',
            display: (showHouseProfitTotal || showHouseProfitEV) && !isMobilePortrait(),
            gridLines: {
              color: "#262626"
            }
          },
          {
            id: 'E',
            display: showAvgBetSize && !isMobilePortrait(),
            gridLines: {
              color: "#262626"
            }
          },
          {
            id: 'A',
            display: showEthWagered && !isMobilePortrait(),
            gridLines: {
              color: "#262626"
            }
          },
          {
            id: 'C',
            display: (showPlayerCount || showNewPlayerCount) && !isMobilePortrait(),
            gridLines: {
              color: "#262626"
            }
          },
          {
            id: 'D',
            display: showBetCount && !isMobilePortrait(),
            gridLines: {
              color: "#262626"
            }
          },
        ]
      },
      lineAtIndex: lineAtIndex,
      showPeriodMarkers: showPeriodMarkers
    };
  }
  
}