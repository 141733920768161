import React from "react";

class ClickOutsideCatcher extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: this.createUniqueId()
    }
  }

  handleClick = event => {
    const {id} = this.state;
    if (event.target.closest(`#${id}`) || (document.activeElement && document.activeElement.closest(`#${id}`))) {
      return;
    }
    const {onCatch} = this.props;
    if(onCatch)onCatch();
  };

  createUniqueId = () => {
    return `keep-focus-here-${new Date().getTime().toString()}`;
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  };

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  };

  render() {
    const {children} = this.props;
    const {id} = this.state;
    return (
      <div id={id}>
        {children}
      </div>

    )
  }
}

export default ClickOutsideCatcher;