import {WEEK} from "../util/misc";

export const quarters = [
    // 18th, latest period
    {
        start: 1627897199,
        end: 1627897199 + (12 * WEEK),
        profits: 0,
        name: 'QXVIII',
        unclaimed: 0,
    },
    // 17th
    {
        start: 1619396579,
        end: 1627897199,
        profits: -6,
        name: 'QXVII',
        unclaimed: 0,
    },
    // 16th
    {
        start: 1610692640,
        end: 1619396579,
        profits: 66,
        name: 'QXVI',
        unclaimed: 13.117,
    },
    // 15th
    {
        start: 1604142040,
        end: 1610692640,
        profits: 224,
        name: 'QXV',
        unclaimed: 49.72,
    },
    // 14th
    {
        start: 1596447031,
        end: 1604142040,
        profits: 593,
        name: 'QXIV',
        unclaimed: 78.37,
    },
    // 13th
    {
        start: 1596447031,
        end: 1596447031,
        profits: -205,
        name: 'QXIII',
        unclaimed: 0,
    },
    // twelfth
    {
        start: 1579262408,
        end: 1587026452,
        profits: 840,
        name: 'Q1 20',
        unclaimed: 130.88,
    },
    // eleventh
    {
        start: 1572499751,
        end: 1579262408,
        profits: 432,
        name: 'Q4 19',
        unclaimed: 62.01967,
    },
    // tenth
    {
        start: 1564283125,
        end: 1572499751,
        profits: 310,
        name: 'Q3 19',
        unclaimed: 59.23,
    },
    // ninth
    {
        start: 1556498604
        , end: 1564283125
        , profits: 1176
        , name: 'Q2 19'
        , unclaimed: 219.557725583148838988
    },
    // eight
    {
        start: 1549272116
        , end: 1556498604
        , profits: 963.27
        , name: 'Q1 19'
        , unclaimed: 102
    },
    // seventh
    {
        start: 1541401700
        , end: 1549264100
        , profits: -210.17
        , name: 'Q4 18'
        , unclaimed: 0
    },
    // sixth
    {
        start: 1533053506
        , end: 1541401700
        , profits: -33.10
        , name: 'Q3 18'
        , unclaimed: 0
    },
    // fifth
    {
        start: 1525215123
        , end: 1533053506
        , profits: 633
        , name: 'Q2 18'
        , unclaimed: 74
    },
    // fourth
    {
        start: 1517734143
        , end: 1525215123
        , profits: 1237.98
        , name: 'Q1 18'
        , unclaimed: 59
    },
    // third period
    {
        start: 1509328415
        , end: 1517734143
        , profits: -804.98
        , name: 'Q3 17'
        , unclaimed: 0
    },
    // second period
    {
        start: 1501835640
        , end: 1509328415
        , profits: 1111.069147281031152130
        , name: 'Q2 17'
        , unclaimed: 125.4
    },
    {
        // first period
        start: 1494986401
        , end: 1501835640
        , profits: 1419.240155488823749071
        , name: 'Q1 17'
        , unclaimed: 111.81
    }
];

export default class Quarters {

    static getAll() {
        return quarters;
    }

    static getNegativeBalance(offset = 0) {
        let balance = 0;
        for (let i = quarters.length - 1; i > offset; i--) {
            const q = quarters[i];
            if (q.profits < 0 || (balance < 0 && q.profits > 0)) {
                balance += q.profits;
            }
            if (balance > 0) balance = 0;
        }
        return balance;
    }

    static getDateMap() {
        let map = {};
        quarters.forEach(period => {
            const d = new Date(period.end * 1000);
            const datestring = d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) + "-" + ("0" + d.getDate()).slice(-2);
            period.dateString = datestring;
            map[datestring] = period;
        });
        return map;
    }

    static getCurrentQuarter() {
        return {
            ...quarters[0]
        }
    }

}