import React from "react";
import {
    getLiquidityContractState, getTokenContractState, LIQUIDITY_CONTRACT_ADDRESS,
} from "../../../util/liquidity-contract/liquidity_contract";
import Timer from "../../util/Timer";
import connect from "react-redux/es/connect/connect";
import {getTrades, loadLiquidityContractTrades} from "../../../ducks/liquidityContract";
import TradeList from "./TradeList";

import './liquidity-contract.scss';
import LinkIcon from "mdi-react/LinkIcon";
import {hasWeb3} from "../../../util/web3";
import TradeDialogBuy from "./TradeDialogBuy";
import TradeDialogSell from "./TradeDialogSell";
import Transactions from "./Transactions";
import Web3Missing from "./Web3Missing";

class LiquidityContract extends React.Component {

    state = {
        marketIsOpen: true,
        buyingRate: 0,
        sellingRate: 0,
        commissionRate: 0,
        amountToBuy: 0.25,
        amountToSell: 100,
        connected: false,
        tokenIsFrozen: false,
        tokenIsUnlocked: true,
        diceBalance: 0,
        ethBalance: 0,
        transactions: [],
        errorMessage: undefined,
    };

    checkLiqContract = async () => {
        if (hasWeb3()) {
            const {amountToBuy, amountToSell} = this.state;

            const {
                buyRate,
                sellRate,
                commissionRatio,
                isTradingDeactivated,
            } = await getLiquidityContractState(amountToBuy, amountToSell)

            this.setState({
                buyingRate: buyRate,
                sellingRate: sellRate,
                commissionRate: commissionRatio,
                marketIsOpen: !isTradingDeactivated,
            })
        }
    };

    changeAmountToBuy = e => this.setState({
        amountToBuy: e.target.value
    });

    changeAmountToSell = e => this.setState({
        amountToSell: e.target.value
    });

    setErrorMessage = msg => this.setState({
        errorMessage: msg
    });

    checkToken = async () => {
        if (hasWeb3()) {
            const {
                isTokenFrozen,
                isTokenUnlocked,
                ethBalance,
                diceBalance,
            } = await getTokenContractState()

            this.setState({
                tokenIsFrozen: isTokenFrozen,
                tokenIsUnlocked: isTokenUnlocked,
                ethBalance: ethBalance,
                diceBalance: diceBalance,
            })
        }
    };

    componentDidMount = () => {
        if (hasWeb3()) {
            const {ethereum} = window;
            const component = this;
            ethereum && ethereum.on('accountsChanged', function () {
                component.checkToken();
            })
        }
    };

    render() {

        const {loadLiquidityContractTrades, trades} = this.props;
        const {amountToBuy, buyingRate, amountToSell, sellingRate, commissionRate, marketIsOpen, tokenIsUnlocked, diceBalance, ethBalance, tokenIsFrozen, errorMessage} = this.state;
        const buyPayout = (amountToBuy / buyingRate) * (1 - commissionRate);
        const sellPayout = (amountToSell * sellingRate) * (1 - commissionRate);

        const disabled = !marketIsOpen || tokenIsFrozen;
        // const disabled = false;

        return (
            <div id='liquidity-contract'>
                <Timer
                    action={this.checkLiqContract}
                    interval={15}
                    key={`${amountToBuy}/${amountToSell}`}
                />
                <Timer
                    action={this.checkToken}
                    interval={15}
                />
                <Timer
                    action={loadLiquidityContractTrades}
                    interval={60}
                />

                <h1>
                    DICE Token Liquidity Contract
                </h1>

                {errorMessage &&
                <div className='error'>
                    <h2>
                        Error
                    </h2>
                    {errorMessage}
                </div>
                }

                {hasWeb3() &&
                <>
                    {tokenIsFrozen &&
                    <div className='alert'>
                        <h2>
                            Alert
                        </h2>
                        Trading disabled during payout period. For more information, visit <a
                        href="https://rewards.etheroll.com/" target="_blank"
                        rel="noopener noreferrer">rewards.etheroll.com</a>.
                    </div>
                    }

                    {!tokenIsFrozen && !marketIsOpen &&
                    <div className='alert'>
                        <h2>
                            Alert
                        </h2>
                        Market currently closed. Please check back later.
                    </div>
                    }
                    <div className='rates'>

                        <TradeDialogBuy
                            amountToBuy={amountToBuy}
                            buyPayout={buyPayout}
                            changeAmountToBuy={this.changeAmountToBuy}
                            ethBalance={ethBalance}
                            disabled={disabled}
                            setErrorMessage={this.setErrorMessage}
                        />

                        <TradeDialogSell
                            amountToSell={amountToSell}
                            sellPayout={sellPayout}
                            diceBalance={diceBalance}
                            changeAmountToSell={this.changeAmountToSell}
                            disabled={disabled}
                            unlocked={tokenIsUnlocked}
                            setErrorMessage={this.setErrorMessage}
                        />

                    </div>
                </>
                }

                <Transactions/>

                {!hasWeb3() && <Web3Missing/>}

                <div className={'more-info'}>

                    More info about the initiative here:
                    <a
                        href="https://www.reddit.com/r/etheroll/comments/8zixuy/dice_token_liquidity_contract_v3_live/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <LinkIcon/>
                        r/etheroll
                    </a>

                    <a
                        href={`https://etherscan.io/address/${LIQUIDITY_CONTRACT_ADDRESS}#code`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <LinkIcon/>
                        Read contract on Etherscan
                    </a>


                </div>

                <TradeList
                    trades={trades}
                />

            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        trades: getTrades(state),
    }
};

const mapDispatchToProps = {
    loadLiquidityContractTrades,
};

export default connect(mapStateToProps, mapDispatchToProps)(LiquidityContract);