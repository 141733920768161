import {fetchMessages} from "../services/trollBoxAPI";

export const NAME_SPACE = 'troll-box';

const LOAD_DATA_PENDING = `${NAME_SPACE}/LOAD_DATA_PENDING`;
const LOAD_DATA_FAIL = `${NAME_SPACE}/LOAD_DATA_FAIL`;
const LOAD_DATA_SUCCESS = `${NAME_SPACE}/LOAD_DATA_SUCCESS`;

const SET_MESSAGE = `${NAME_SPACE}/SET_MESSAGE`;

function loadDataPending(){
  return {
    type: LOAD_DATA_PENDING
  }
}

function loadDataFail(error){
  return {
    type: LOAD_DATA_FAIL,
    error: error
  }
}

function loadDataSuccess(messages){
  return {
    type: LOAD_DATA_SUCCESS,
    messages: messages,
  }
}

export function loadTrollBoxMessages(){
  return dispatch => {
    dispatch(loadDataPending());
    fetchMessages().then(
      response => dispatch(loadDataSuccess(response)),
      error => dispatch(loadDataFail(error)),
    )
  }
}

export function setMessage(message){
  return {
    type: SET_MESSAGE,
    message: message,
  }
}

const initialState = {
  isLoading: false,
  isDataLoaded: false,
  error: null,
  messages: [],
  message: "",
};

function trollBoxReducer(state = initialState, action){
  switch(action.type){
    case LOAD_DATA_PENDING:
      return {
        ...state
        , isLoading: true
      };
    case LOAD_DATA_FAIL:
      return {
        ...state,
        isLoading: false,
        isDataLoaded: true,
        error: action.error,
      };
    case LOAD_DATA_SUCCESS:
      return {
        ...state,
        isDataLoaded: true,
        messages: [...action.messages],
      };
    case SET_MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    default:
      return state;
  }
}

function getState(state){
  return state[NAME_SPACE];
}

export function getMessages(state){
  return getState(state).messages;
}

export function getMessage(state){
  return getState(state).message;
}

export function isDataLoaded(state){
  return getState(state).isDataLoaded;
}

export default trollBoxReducer;