import React from 'react';

import './loader-circle.scss';

const LoaderCircle = () => {
    return (
        <div className='loader loader-circle' />
    )
};

export default LoaderCircle;