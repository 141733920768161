import React from 'react';

import CurrentIcon from "mdi-react/CalendarCheckOutlineIcon";
import MenuIcon from "mdi-react/MenuIcon";
import RollsIcon from "mdi-react/DiceMultipleIcon";
import TopListIcon from "mdi-react/TrophyVariantOutlineIcon";
import StatsIcon from "mdi-react/ChartLineIcon";
import NotificationIcon from "mdi-react/BellOutlineIcon";

import './nav-bar.scss';
import {NavLink, withRouter} from "react-router-dom";
import {ToolTip} from "../util/ToolTip";
import {activatePopup} from "../../ducks/popup";
import connect from "react-redux/es/connect/connect";
import ExtendedMenu from "./ExtendedMenu";
import {hasActiveNotification, hasUnreadNotification, updateLastRead} from "../../ducks/notifications";
import Notification from "./Notification";

class NavBar extends React.Component {

  showExtendedMenu = () => {
    const {activatePopup} = this.props;
    activatePopup(<ExtendedMenu />)
  };

  showNotification = () => {
    const {activatePopup, updateLastRead} = this.props;
    updateLastRead();
    activatePopup(<Notification />)
  };

  render() {
    const {hasActiveNotification, hasUnreadNotification} = this.props;
    return (
      <div id='nav-bar'>
        <nav>
          <div className='nav-bar__button'>
            <ToolTip text={`Current Status`}>
              <NavLink exact to='/' className='orb'>
                <CurrentIcon/>
              </NavLink>
            </ToolTip>
          </div>
          <div className='nav-bar__button'>
            <ToolTip text={`Live Rolls`}>
              <NavLink to='/rolls' className='orb'>
                <RollsIcon/>
              </NavLink>
            </ToolTip>
          </div>
          <div className='nav-bar__button'>
            <ToolTip text={`Statistics`}>
              <NavLink to='/stats' className='orb'>
                <StatsIcon/>
              </NavLink>
            </ToolTip>
          </div>
          <div className='nav-bar__button'>
            <ToolTip text={`Top Players`}>
              <NavLink to='/top-players' className='orb'>
                <TopListIcon/>
              </NavLink>
            </ToolTip>
          </div>
          <div className='nav-bar__button'>
            <ToolTip text={'More'} setRight={true}>
              {/*eslint-disable-next-line*/}
              <a className='orb'
                 onClick={this.showExtendedMenu}
                 tabIndex={0}
              >
                <MenuIcon/>
              </a>
            </ToolTip>
          </div>
          {hasActiveNotification &&
          <div className='notification'>
            <div className='nav-bar__button'>
              {/*eslint-disable-next-line*/}
              <a className={`orb ${hasUnreadNotification && 'unread'}`}
                 onClick={this.showNotification}
                 tabIndex={0}
              >
                <NotificationIcon/>
              </a>
            </div>
          </div>
          }
        </nav>
      </div>
    )
  }
}

const mapStateToProps = state =>{
  return {
    hasActiveNotification: hasActiveNotification(state),
    hasUnreadNotification: hasUnreadNotification(state),
  }
};

const mapDispatchToProps = {
  activatePopup,
  updateLastRead,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));