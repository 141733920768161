import React from 'react';
import connect from "react-redux/es/connect/connect";
import {lastHourStats} from "../../ducks/rolls";

import './hour-stats-summary.scss';
import Counter from "../util/Counter";
import NumberUpIcon from "mdi-react/ArrowUpIcon";
import NumberDownIcon from "mdi-react/ArrowDownIcon";
import {activatePopup} from "../../ducks/popup";
import HourStats from "./HourStats";

const HourStatsSummary = ({lastHourStats, activatePopup}) => {
  const {rollCount, totalWagered, playerCount, medianRollDuration, houseProfit} = lastHourStats;
  const showMore = () => {
    activatePopup(<HourStats/>);
  };
  return (
    <div id='last-hour-summary'>

      <h3>
        Last hour summary
      </h3>

      <div className='data-points'>

        <div className='data-points__data-point'>
          <h4>
            House profit/ loss
          </h4>
          <span>
            {houseProfit >= 0 ?
              <NumberUpIcon className='icon_win'/> :
              <NumberDownIcon className='icon_loss'/>
            }
            <Counter
              amount={houseProfit}
              absolute={true}
              decimals={2}
            /> ETH
          </span>
        </div>

        <div className='data-points__data-point'>
          <h4>
            Players
          </h4>
          <span>
            <Counter
              amount={playerCount}
              decimals={0}
            />
          </span>
        </div>

        <div className='data-points__data-point'>
          <h4>
            Number of rolls
          </h4>
          <span>
            <Counter
              amount={rollCount}
              decimals={0}
            />
          </span>
        </div>

        <div className='data-points__data-point'>
          <h4>
            Wagered
          </h4>
          <span>
            <Counter
              amount={totalWagered}
              decimals={2}
            /> ETH
          </span>
        </div>

        <div className='data-points__data-point'>
          <h4>
            Median roll duration
          </h4>
          <span>
            <Counter
              amount={medianRollDuration * -1}
              decimals={2}
              absolute={true}
            />s
          </span>
        </div>

        <div
          className='data-points__data-point clickable'
          tabIndex={0}
          onClick={showMore}
        >
          <h4>
            More
          </h4>
          <span>
            Open
          </span>
        </div>

      </div>
    </div>
  )
};

const mapStateToProps = state => {
  return {
    lastHourStats: lastHourStats(state),
  }
};


const mapDispatchToProps = {
  activatePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(HourStatsSummary);

