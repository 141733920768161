import React from 'react';
import Card from "./Card";
import {ToolTip} from "../util/ToolTip";
import EthereumIcon from "mdi-react/EthereumIcon";
import YourShareIcon from "mdi-react/ChartPieIcon";
import {numberFormat} from "../../util/formatters/numbers";
import NumberUpIcon from "mdi-react/ArrowUpIcon";
import NumberDownIcon from "mdi-react/ArrowDownIcon";
import FiatIcon from "mdi-react/CashIcon";
import {getRate} from "../../ducks/rates";
import connect from "react-redux/es/connect/connect";
import {getHouseProfit, getHouseProfit24h, getHouseProfitQuarter} from "../../ducks/status";
import {DICE_TOKENS_TOTAL} from "../../data/misc";
import Quarters from "../../data/quarters";
import Counter from "../util/Counter";
import {getAmount} from "../../ducks/holdings";
import {getCurrency} from "../../ducks/currency";

const CardYourShare = ({diceAmount, houseProfits, houseProfitsQuarter, houseProfit24h, currency}) => {
  const ratio = diceAmount / DICE_TOKENS_TOTAL;
  const yourShare24h = houseProfit24h * ratio;
  const yourShareRaw = houseProfits * ratio;
  const yourShare = yourShareRaw >= 0 ? houseProfits * ratio : 0;
  const negativeBalance = Math.abs(Quarters.getNegativeBalance());
  return (
    <Card className='your-share'>
      <div className='card__icon-background'>
        <YourShareIcon/>
      </div>
      <h4>
        <YourShareIcon/>
        Your share
      </h4>
      <div className='your-share__data-points'>
        <div className='your-share__data-points__data-point'>
          <ToolTip text={`Your share in ETH`} setRight={true}>
            <div className='your-share__data-points__data-point__main-value'>
              <EthereumIcon/>
              <Counter
                amount={yourShare}
                absolute={true}
                decimals={4}
              />
              {yourShare === 0 && `*`}
            </div>
            <div className='your-share__data-points__data-point__sub-value'>
              {yourShare24h >= 0 ?
                <NumberUpIcon className='icon_positive'/> :
                <NumberDownIcon className='icon_negative'/>}
              <Counter
                amount={yourShare24h}
                absolute={true}
                decimals={4}
              />
            </div>
          </ToolTip>
        </div>
        <div className='your-share__data-points__data-point'>
          <ToolTip text={`Your share in ${currency.code}`} setRight={true}>
            <div className='your-share__data-points__data-point__main-value'>
              <FiatIcon/>
              <Counter
                amount={yourShare * currency.rate}
                absolute={true}
                decimals={currency.decimals}
                prefix={currency.prefix}
                postfix={currency.postfix}
              />
            </div>
            <div className='your-share__data-points__data-point__sub-value'>
              {yourShare24h >= 0 ?
                <NumberUpIcon className='icon_positive'/> :
                <NumberDownIcon className='icon_negative'/>}
              <Counter
                amount={yourShare24h * currency.rate}
                absolute={true}
                decimals={currency.decimals}
                prefix={currency.prefix}
                postfix={currency.postfix}
              />
            </div>
          </ToolTip>
        </div>
        <div className='your-share__data-points__data-point'>
          <ToolTip text={`24h change`} setRight={true}>
          </ToolTip>
        </div>
      </div>
      {negativeBalance >= houseProfitsQuarter &&
      <div className='card__footer'>
        Profits must exceed {numberFormat(negativeBalance, 2)} eth for dividends to be awarded
      </div>
      }
    </Card>
  )
};

const mapStateToProps = state => {
  return {
    houseProfits: getHouseProfit(state),
    houseProfitsQuarter: getHouseProfitQuarter(state),
    houseProfit24h: getHouseProfit24h(state),
    rate: getRate(state),
    diceAmount: getAmount(state),
    currency: getCurrency(state),
  }
};

export default connect(mapStateToProps)(CardYourShare);