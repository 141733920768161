import React from 'react';
import {getHouseProfit, loadBalance} from "../../ducks/status";
import connect from "react-redux/es/connect/connect";
import Timer from "./Timer";
import {loadRates} from "../../ducks/rates";
import {isProd} from "../../util/misc";
import DocTitle from "../layout/DocTitle";
import {numberFormat} from "../../util/formatters/numbers";
import Quarters from "../../data/quarters";

const GlobalTimers = ({loadBalance, loadRates, houseProfits}) => (
  <>
    <Timer
      action={loadBalance}
      offset={10}
      interval={30}
    />
    <Timer
      action={loadRates}
      offset={20}
      interval={30}
      instantly={isProd()}
    />
    <DocTitle
        text={numberFormat(houseProfits - Quarters.getNegativeBalance())}
    />
  </>
);

const mapStateToProps = state => {
  return {
      houseProfits: getHouseProfit(state),
  }
};

const mapDispatchToProps = {
  loadBalance,
  loadRates
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalTimers);