import {fetchTrades} from "../services/liquidityContractAPI";
import {activatePopup} from "./popup";
import TransactionStatus from "../components/tab-exchanges/liquidity-contract/TransactionStatus";
import React from "react";

export const NAME_SPACE = 'liquidity-contract';

const LOAD_DATA_PENDING = `${NAME_SPACE}/LOAD_DATA_PENDING`;
const LOAD_DATA_FAIL = `${NAME_SPACE}/LOAD_DATA_FAIL`;
const LOAD_DATA_SUCCESS = `${NAME_SPACE}/LOAD_DATA_SUCCESS`;

const ADD_TRANSACTION = `${NAME_SPACE}/ADD_TRANSACTION`;

function loadDataPending() {
    return {
        type: LOAD_DATA_PENDING
    }
}

function loadDataFail(error) {
    return {
        type: LOAD_DATA_FAIL,
        error: error
    }
}

function loadDataSuccess(trades) {
    return {
        type: LOAD_DATA_SUCCESS,
        trades: trades,
    }
}

export const addTransaction = transaction => dispatch => {
    dispatch({
        type: ADD_TRANSACTION,
        transaction
    });
    dispatch(activatePopup(
        <TransactionStatus hash={transaction} />
    ))
};

export function loadLiquidityContractTrades() {
    return dispatch => {
        dispatch(loadDataPending());
        fetchTrades().then(
            response => dispatch(loadDataSuccess(response)),
            error => dispatch(loadDataFail(error)),
        )
    }
}


const initialState = {
    isLoading: false,
    isDataLoaded: false,
    error: null,
    trades: [],
    // transactions: ['0x29a2e7dfff20d37ab74b26d3637a26c778c8c223d1d95080aa7c8fef4ca53701'],
    transactions: [],
};

function liquidityContract(state = initialState, action) {
    switch (action.type) {
        case LOAD_DATA_PENDING:
            return {
                ...state
                , isLoading: true
            };
        case LOAD_DATA_FAIL:
            return {
                ...state,
                isLoading: false,
                isDataLoaded: true,
                error: action.error,
            };
        case LOAD_DATA_SUCCESS:
            return {
                ...state,
                isDataLoaded: true,
                trades: [...action.trades],
            };
        case ADD_TRANSACTION:
            return {
                ...state,
                transactions: [action.transaction, ...state.transactions],
            };
        default:
            return state;
    }
}

function getState(state) {
    return state[NAME_SPACE];
}

export function getTrades(state) {
    return getState(state).trades;
}

export const getTransactions = state => getState(state).transactions;

export default liquidityContract;