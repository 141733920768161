import axios from "axios/index";

export function fetchTopPlayers(metric, period){

    const host = process.env.REACT_APP_API_HOST;
    const path = `/top-players/${transformMetric(metric)}/${transformPeriod(period)}`;

    return axios.get(host + path)
        .then(response => {
            return response
        })
        .catch(error => {
            throw new Error(error)
        })

}

const transformMetric = metric => {
    switch(metric){
        case 'rolls':
            return 'bet-count';
        case '':
            return 'bet-count';
        default:
            return metric;
    }
};

const transformPeriod = period => period.toUpperCase();