import React, {useEffect} from 'react';
import {getIsLoading, loadTopPlayers, TOP_PLAYERS_METRICS,} from "../../ducks/topPlayers";
import Periods from "./Periods";
import LoaderHorizontal from "../util/LoaderHorizontal";
import {useDispatch, useSelector} from "react-redux";
import TopList from "./TopList";
import './tab-top-players.scss';

const TabTopPlayers = ({match}) => {

    const dispatch = useDispatch();
    const isLoading = useSelector(getIsLoading);

    const {
        metric = TOP_PLAYERS_METRICS.PROFIT,
        period = "24h",
    } = match.params;

    useEffect(()=>{
        dispatch(loadTopPlayers(metric, period))
    },[metric, period, dispatch]);

    return (
        <div
            id={`tab-top-players`}
            className={isLoading ? 'loading' : 'loaded'}
            key={`${metric}/${period}`}
        >
            <h1>
                Top Players
            </h1>

            <Periods
                metric={metric}
                period={period}
            />

            {isLoading &&
            <LoaderHorizontal/>
            }

            {!isLoading &&
            <TopList
                metric={metric}
                period={period}
            />
            }
        </div>
    )

};
export default TabTopPlayers;