export const DOCUMENT_TITLE = 'MyEtheroll v3';

export const SECOND = 1;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;

export function isProd(){
  return process.env.REACT_APP_ENV === 'PROD'
}

export function getMedian(values){
  if(values.length === 0)return 0;
  values.sort( function(a,b) {return a - b} );
  const half = Math.floor(values.length/2);
  if(values.length % 2)
    return values[half];
  else
    return (values[half-1] + values[half]) / 2.0;
}