import LiquidityContractABI from './abi/LiquidityContractABI';
import EtherollTokenABI from './abi/EtherollTokenABI';
import {enableWeb3, ethToWei, getWeb3, hasWeb3, resolveOnHash, weiToEth} from "../web3";

export const LIQUIDITY_CONTRACT_ADDRESS = '0xe9b8ee108004fe887296a4e1866a173275c0499e';
// export const LIQUIDITY_CONTRACT_ADDRESS = '0x8524a50e17e80b1a38ffc1e2c88d6ec5fbf29bd2';
export const LIQUIDITY_CONTRACT_OWNERS = ['0x71cf9cc55614a4dc115bdf86bd2a732499aaae63'];

export const DICE_TOKEN_CONTRACT = '0x2e071d2966aa7d8decb1005885ba1977d6038a65';
// export const DICE_TOKEN_CONTRACT = '0x5ac09bd438ad4a4acb7e68072a307d6ba55349e4';
export const DICE_DECIMALS = 10000000000000000;

export const TOKEN_APPROVE_AMOUNT = 1000000;

export async function getLiquidityContractState(ethToBuy, diceToSell) {
    if (hasWeb3()) {

        await enableWeb3();
        const web3 = getWeb3();

        const liquidityContract = new web3.eth.Contract(LiquidityContractABI, LIQUIDITY_CONTRACT_ADDRESS)

        let buyRate = 0
        let sellRate = 0

        if (_isNumber(ethToBuy) && ethToBuy > 0) {
            const ethAmount = web3.utils.toBN(ethToWei(ethToBuy))
            const amountToBuy = await liquidityContract.methods.get_amount_buy(web3.utils.toHex(ethAmount)).call()
            const dice = amountToBuy / DICE_DECIMALS
            buyRate = ethToBuy / dice
        }

        if (_isNumber(diceToSell) && diceToSell > 0) {
            const decimals = web3.utils.toBN(DICE_DECIMALS)
            const diceAmount = decimals.mul(web3.utils.toBN(diceToSell))
            const amountToSell = await liquidityContract.methods.get_amount_sell(web3.utils.toHex(diceAmount)).call()
            const eth = weiToEth(amountToSell)
            sellRate = eth / diceToSell
        }

        const commissionRatio = await liquidityContract.methods.commission_ratio().call() / 1000000000000000000

        const isTradingDeactivated = await liquidityContract.methods.trading_deactivated().call()

        return {
            buyRate,
            sellRate,
            commissionRatio,
            isTradingDeactivated,
        }

    }
}

export async function getTokenContractState() {
    if (hasWeb3()) {

        await enableWeb3();
        const web3 = getWeb3();

        const accounts = await web3.eth.getAccounts()
        const account = accounts[0]

        const tokenContract = new web3.eth.Contract(EtherollTokenABI, DICE_TOKEN_CONTRACT)

        const isTokenFrozen = await tokenContract.methods.tokensFrozen().call()

        const diceBalance = await tokenContract.methods.balanceOf(account).call() / DICE_DECIMALS

        const weiBalance = await web3.eth.getBalance(account)
        const ethBalance = weiToEth(weiBalance)

        const allowance = await tokenContract.methods.allowance(account, LIQUIDITY_CONTRACT_ADDRESS).call()
        const diceAllowance = allowance / DICE_DECIMALS

        return {
            isTokenFrozen,
            isTokenUnlocked: diceAllowance > TOKEN_APPROVE_AMOUNT / 2,
            ethBalance,
            diceBalance,
        }

    }
}

function _isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0)
}

export const createBuyTokenTransaction = async ethAmount => {
    if (hasWeb3()) {

        await enableWeb3();
        const web3 = getWeb3();

        const accounts = await web3.eth.getAccounts()
        const account = accounts[0]

        const options = {
            from: account,
            to: LIQUIDITY_CONTRACT_ADDRESS,
            value: ethToWei(ethAmount),
            gas: 250000,
        }

        const transaction = web3.eth.sendTransaction(options)
        return await resolveOnHash(transaction)

    }
}




export const createUnlockTokenTransaction = async () => {
    if (hasWeb3()) {

        await enableWeb3()
        const web3 = getWeb3()

        const accounts = await web3.eth.getAccounts()
        const account = accounts[0]

        const tokenContract = new web3.eth.Contract(EtherollTokenABI, DICE_TOKEN_CONTRACT)
        const options = {
            from: account
        }

        const diceAmountBigNumber = web3.utils.toBN(TOKEN_APPROVE_AMOUNT).mul(web3.utils.toBN(DICE_DECIMALS))
        const transaction = tokenContract.methods.approve(LIQUIDITY_CONTRACT_ADDRESS, diceAmountBigNumber.toString()).send(options)
        return await resolveOnHash(transaction)

    }
};

export const createSellTokenTransaction = async diceAmount => {
    if (hasWeb3()) {

        await enableWeb3()
        const web3 = getWeb3()

        const accounts = await web3.eth.getAccounts()
        const account = accounts[0]

        const liquidityContract = new web3.eth.Contract(LiquidityContractABI, LIQUIDITY_CONTRACT_ADDRESS)
        const options = {
            from: account
        }

        if (_isNumber(diceAmount) && diceAmount > 0) {
            const diceAmountBigNumber = web3.utils.toBN(diceAmount).mul(web3.utils.toBN(DICE_DECIMALS))
            const transaction = liquidityContract.methods.sell_tokens(diceAmountBigNumber.toString()).send(options)
            return await resolveOnHash(transaction)
        }

    }
};