import React from 'react';
import Card from "./Card";
import {ToolTip} from "../util/ToolTip";

import WageredIcon from "mdi-react/EthereumIcon";
import ClockIcon from "mdi-react/ClockOutlineIcon";
import NumberUpIcon from "mdi-react/ArrowUpIcon";
import NumberDownIcon from "mdi-react/ArrowDownIcon";
import PlayerIcon from "mdi-react/AccountIcon";
import BetsIcon from "mdi-react/DiceMultipleIcon";
import {getBets24h, getHouseProfit24h, getPlayers24h, getWagered24h} from "../../ducks/status";
import connect from "react-redux/es/connect/connect";
import Counter from "../util/Counter";

const CardActivity = ({ bets24h, wagered24h, houseProfit24h, players24h }) => {
  return (
    <Card className='stats-24h'>
      <div className='card__icon-background'>
        <ClockIcon/>
      </div>
      <h4>
        <ClockIcon/>
        24h activity
      </h4>
      <div className='stats-24h__data-points'>
        <div className='stats-24h__data-points__data-point'>
          <ToolTip text={`House profit/ loss in ETH`} setRight={true}>
            {houseProfit24h >= 0 ?
              <NumberUpIcon className='icon_positive'/> :
              <NumberDownIcon className='icon_negative'/>}
            <Counter
              amount={houseProfit24h}
              absolute={true}
              decimals={2}
            />
          </ToolTip>
        </div>
        <div className='stats-24h__data-points__data-point'>
          <ToolTip text={`Number of players`} setRight={true}>
            <PlayerIcon/>
            <Counter
              amount={players24h}
            />
          </ToolTip>
        </div>
        <div className='stats-24h__data-points__data-point'>
          <ToolTip text={`Number of rolls`} setRight={true}>
            <BetsIcon/>
            <Counter
              amount={bets24h}
            />
          </ToolTip>
        </div>
        <div className='stats-24h__data-points__data-point'>
          <ToolTip text={`Total ETH wagered`}  setRight={true}>
            <WageredIcon/>
            <Counter
              amount={wagered24h}
              decimals={2}
              smartDecimals={true}
            />
          </ToolTip>
        </div>
      </div>
    </Card>
  )
};


const mapStateToProps = state => {
  return {
    bets24h: getBets24h(state),
    wagered24h: getWagered24h(state),
    houseProfit24h: getHouseProfit24h(state),
    players24h: getPlayers24h(state),
  }
};

export default connect(mapStateToProps)(CardActivity);