import React from 'react';
import {NavLink} from "react-router-dom";

import './extended-menu.scss';
import ClickOutsideCatcher from "../util/ClickOutsideCatcher";

import EstimateIcon from "mdi-react/CalculatorIcon";
import DividendHistoryIcon from "mdi-react/CalendarStarIcon";
import ExchangesIcon from "mdi-react/BankIcon";
import BuyIcon from "mdi-react/CoinsIcon";
import MessagesIcon from "mdi-react/MessageTextOutlineIcon";
import ContractsIcon from "mdi-react/FileSearchOutlineIcon";

const ExtendedMenu = ({closePopup}) => (
  <div id={`extended-menu`}>
    <h2>
        More Stuff
    </h2>
    <div id='extended-menu__links'>
      {/*<NavLink to='/liquidity-contract'>*/}
      {/*  <BuyIcon />*/}
      {/*  Buy/ sell DICE*/}
      {/*</NavLink>*/}
      <a href="/swap?inputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&outputCurrency=0x2e071D2966Aa7D8dECB1005885bA1977D6038A65" target="_blank">
        <BuyIcon />
        Buy/ sell DICE
      </a>
      <NavLink to='/exchanges'>
        <ExchangesIcon />
        Exchanges offering DICE
      </NavLink>
      <NavLink to='/estimate'>
        <EstimateIcon />
        Dividend estimate
      </NavLink>
      <NavLink to='/dividends'>
        <DividendHistoryIcon />
        Dividend history
      </NavLink>
      <NavLink to='/contracts'>
        <ContractsIcon />
        Smart contract information
      </NavLink>
      <NavLink to='/troll-box'>
        <MessagesIcon />
        Leave message/ Support us
      </NavLink>
      <ClickOutsideCatcher onCatch={closePopup} />
    </div>
  </div>
);

export default ExtendedMenu;