import React from 'react';
import Counter from "../../util/Counter";
import {
    createSellTokenTransaction,
    createUnlockTokenTransaction
} from "../../../util/liquidity-contract/liquidity_contract";
import {useDispatch} from "react-redux";
import {addTransaction} from "../../../ducks/liquidityContract";

const TradeDialogSell = ({amountToSell, sellPayout, changeAmountToSell, diceBalance, disabled, unlocked, setErrorMessage}) => {

    const dispatch = useDispatch()

    const attemptUnlocking = async () => {
        try {
            const transactionHash = await createUnlockTokenTransaction()
            dispatch(addTransaction(transactionHash))
            setErrorMessage()
        } catch (error) {
            setErrorMessage(error.message)
        }
    }

    const sellTokens = async () => {
        if (amountToSell > diceBalance) {
            setErrorMessage('Insufficient funds')
            return
        }
        try {
            const transactionHash = await createSellTokenTransaction(amountToSell)
            dispatch(addTransaction(transactionHash))
            setErrorMessage()
        } catch (error) {
            setErrorMessage(error.message)
        }
    }

    const rate = amountToSell === 0 ? 0 : sellPayout / amountToSell;

    return (
        <div className='rates__rate selling'>
            <h2>
                Selling
            </h2>
            <div className='amount'>
                <div className=''>
                    Specify how many DICE tokens you wish to sell
                </div>
                <input
                    type='number'
                    onChange={changeAmountToSell}
                    value={amountToSell}
                />
                <button
                    disabled={disabled || !unlocked}
                    onClick={sellTokens}
                    title={disabled ? 'Trading currently disabled' : null}
                >
                    SELL
                </button>
                {!unlocked && !disabled &&
                <button
                    onClick={attemptUnlocking}
                    title={'Before you can sell your tokens you must allow the Liquidity Contract to move tokens on your behalf.'}
                    className={'unlock'}
                >
                    UNLOCK
                </button>
                }
            </div>
            <div>
                <div className='payout'>
                    Payout:
                    <Counter
                        amount={sellPayout !== Infinity ? sellPayout : 0}
                        smartDecimals={true}
                        postfix={' ETH'}
                    />
                </div>
                <div className='rate'>
                    Rate:
                    <Counter
                        amount={rate || 0}
                        postfix={' DICE/ETH'}
                        decimals={6}
                    />
                </div>
                <div className='balance'>
                    Your balance:
                    <Counter
                        amount={diceBalance || 0}
                        postfix={' DICE'}
                        decimals={2}
                    />
                </div>
            </div>

        </div>
    )
};

export default TradeDialogSell;