import axios from "axios/index";

export function fetchRolls(player){

  const host = process.env.REACT_APP_API_HOST;
  let path = player ? `/latest-rolls/${player}` : `/latest-rolls`;

  return axios.get(host + path)
    .then(response => {
      return {
        rolls: transformRollsFromWS(response.data.rolls),
        stats: transformStatsFromWS(response.data.stats),
      }
    })
    .catch(error => {
      throw new Error(error)
    })

}

function transformRollsFromWS(rolls){
  if(!rolls){
    throw new Error('WS Feil...')
  }
  return rolls.reduce((map, { id, ...rest }) => (
    { ...map, [id]: { new: true, ...rest } }
  ), {});
  // return rolls.map(roll => {return {...roll, new: true }}).reverse();
}

function transformStatsFromWS(stats){
  return {
    ...stats
  }
}