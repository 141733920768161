import {getFromLocalStorage, saveToLocalStorage} from "../util/persistance";
import {getRateFor} from "./rates";

export const NAME_SPACE = 'currency';
export const CURRENCIES = {
  USD: {code: "USD", name: "US Dollars", prefix: "$", postfix: "", decimals: 2},
  EUR: {code: "EUR", name: "Euro", prefix: "€", postfix: "", decimals: 2},
  GBP: {code: "GBP", name: "GBP", prefix: "£", postfix: "", decimals: 2},
  CHF: {code: "CHF", name: "Swiss franc", prefix: "Fr. ", postfix: "", decimals: 2},
  BTC: {code: "BTC", name: "Bitcoin", prefix: "฿", postfix: "", decimals: 4},
  DICE: {code: "DICE", name: "ÐICE", prefix: "", postfix: " ÐICE", decimals: 0},
};

const DEFAULT_CURRENCY = 'USD';
const SET_CURRENCY = `${NAME_SPACE}/SET_CURRENCY`;

export const setCurrency = code => {
  return {
    type: SET_CURRENCY,
    code: code,
  }
};

const initialState = {
  currency: getFromLocalStorage(`${NAME_SPACE}/currency`) || DEFAULT_CURRENCY
};

function currencyReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENCY:
      const code = CURRENCIES[action.code] ? action.code : DEFAULT_CURRENCY;
      saveToLocalStorage(`${NAME_SPACE}/currency`, code);
      return {
        ...state
        , currency: code
      };
    default:
      return state;
  }
}

function getState(state) {
  return state[NAME_SPACE];
}

export function getCurrency(state) {
  const currency = getState(state).currency;
  return {
    ...CURRENCIES[currency],
    rate: getRateFor(state, currency),
  };
}

export default currencyReducer;