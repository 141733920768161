import Web3 from "web3";
import {BigNumber} from "bignumber.js";

export function hasWeb3() {
    return typeof window.ethereum !== 'undefined'
}

export const getProvider = () => window['ethereum']

export const enableWeb3 = async () => {
    await getProvider().enable()
}

export const getWeb3 = () => {
    return new Web3(getProvider())
}

export const ethToWei = value => new BigNumber(value * 10 ** 18)
export const weiToEth = value => value / 10 ** 18

export const resolveOnHash = transaction =>
    new Promise((resolve, reject) => {
        transaction.on('transactionHash', (transactionHash) => {
            resolve(transactionHash)
        }).on('error', error => {
            reject(error)
        });
    });