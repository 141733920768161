import axios from "axios/index";

export function fetchMessages(){

  const host = process.env.REACT_APP_API_HOST;
  const path = '/latest-ticker-messages';

  return axios.get(host + path)
    .then(response => {
      return response.data
    })
    .catch(error => {
      throw error
    })

}
