import React from 'react';
import {
    getPlayers,
} from "../../ducks/topPlayers";
import {connect} from "react-redux";

import './periods.scss';
import Player from "./Player";

const TopList = ({players, metric, period}) => (
    <div>
        {players.map((player, index) =>
            <Player
                key={player.user}
                index={index}
                player={player}
                metric={metric}
                period={period}
            />
        )}
    </div>
);


const mapStateToProps = state => {
    return {
        players: getPlayers(state),
    }
};

export default connect(mapStateToProps)(TopList);