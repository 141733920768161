import React from "react";
import './latest-troll.scss'
import {useSelector} from "react-redux";
import {getTrollBoxMessages} from "../../ducks/status";
import {getNow} from "../../util/formatters/timeAndDate";
import AddressFormat from "../../util/formatters/address";
import TimeAgo from "../tab-rolls/TimeAgo";
import {Link} from "react-router-dom";
import Linkify from "react-linkify";
import EditIcon from "mdi-react/PencilOutlineIcon";

const LatestTroll = () => {
    const latestMessages = useSelector(getTrollBoxMessages)
    const latestMessage = latestMessages.find(message => message.valid_from < getNow())
    // const latestMessage = latestMessages[2]

    if(!latestMessage)return null

    const {message, sender, valid_from} = latestMessage

    return (
        <div className={'latest-troll'}>
            <div className={'latest-troll__name'}>
                {AddressFormat.parseHashAddress(sender)}
                <time>
                    <TimeAgo
                        timestamp={valid_from * 1000}
                    />
                </time>
            </div>
            <div className={'latest-troll__message'}>
                <p>
                    <Linkify properties={{
                        target: '_blank',
                        rel: 'noopener noreferrer',
                    }}>
                        {message}
                    </Linkify>
                </p>
                <Link to={'troll-box'} className='reply'>
                    replace message
                    <EditIcon className='edit_icon'/>
                </Link>
            </div>
        </div>
    )
}

export default LatestTroll