import React from "react";
import {WEEK} from "../util/misc";
import {
    NotificationWealthWeekComingUp,
    NotificationWealthWeekHasBegun,
    NotificationWealthWeekIsEnded
} from "../components/notifications/notifications";
import {quarters} from "./quarters";


const messages = [
    {
        start: 1590162196,
        end: 1590162196 + WEEK,
        text: (
            <div>
                <h3>
                    Game is live again
                </h3>
                <p>
                    <i>"Feel free to test out the new contract today now available at etheroll.com as we prepare for our soft re-launch. Game has a minimum bet of 0.1 eth and a very limited maximum profit for the moment."</i>
                </p>
                <p>
                    <a href="https://www.reddit.com/r/etheroll/comments/goh22g/whats_up_with_this/frg33ki/" target='_blank'rel="noopener noreferrer" >- u/etheroll</a>
                </p>
            </div>
        )
    },
    {
        start: 1592225425,
        end: 1592225425 + WEEK,
        text: (
            <>
                <h3>
                    Liquidity Contract has been closed!
                </h3>
                <p>
                    As of this moment the community liquidity contract has been closed indefinitely.
                </p>
                <p>
                    However, easy trading of DICE is still possible on the Uniswap-fork, <a href="https://uniswap10x.exchange/swap?inputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&outputCurrency=0x2e071D2966Aa7D8dECB1005885bA1977D6038A65&exactAmount=1" target='_blank' rel="noopener noreferrer">Uniswap10x</a>!
                </p>
                <p>
                    Using this service is just as easy as using the regular Uniswap. Just search for the DICE/ROL token and trade away. With Uniswap10x a bigger liquidity pool should be possible, enabling even bigger trades than before.
                </p>
                <p>
                    You can read more about the fork <a href="https://read.cash/@uniswap10x/introduction-to-uniswap10xexchange-d44b55eb" target='_blank' rel="noopener noreferrer">here</a>.
                </p>
            </>
        )
    },
    //-------------------------------------------------------------------------------------------------------------------------------------------------
    {
        start: 1620034607 - WEEK,
        end: 1620034607,
        text: (
            <NotificationWealthWeekComingUp
                wealthWeekStart={1620034607}
                wealthWeekEnd={1620639407}
                romanNumeral={quarters[1]?.name?.replace('Q','')}
                n={'sixteen'}
                nth={'16th'}
                pot={quarters[1].profits}
            />
        )
    },
    //-------------------------------------------------------------------------------------------------------------------------------------------------
    {
        start: 1620034607,
        end: 1620639407,
        text: (
            <NotificationWealthWeekHasBegun
                wealthWeekEnd={1620639407}
                romanNumeral={quarters[1]?.name?.replace('Q','')}
                nth={'16th'}
                pot={quarters[1].profits}
            />
        )
    },
    //-------------------------------------------------------------------------------------------------------------------------------------------------
    {
        start: 1620639407,
        end: 1620639407 + WEEK,
        text: (
            <NotificationWealthWeekIsEnded
                romanNumeral={quarters[1]?.name?.replace('Q','')}
                amountUnclaimed={0}
            />
        )
    },
    //-------------------------------------------------------------------------------------------------------------------------------------------------
    {
        // TODO: Remove this
        start: 1612776871,
        end: 1612776871 + WEEK,
        text: (
            <NotificationWealthWeekIsEnded
                romanNumeral={quarters[1]?.name?.replace('Q','')}
                amountUnclaimed={49.72}
            />
        )
    },

];

export default class Notifications {
    static getLatestMessage = () => {
        const now = Date.now() / 1000;
        return messages.find(message => now > message.start && now < message.end);
    };
}