import React from "react";
import './tab-contracts.scss';

import ContractsIcon from "mdi-react/FileDocumentOutlineIcon";
import {TROLL_BOX_CONTRACT_ADR_PROD} from "../../util/troll-box/troll_box";
import {useSelector} from "react-redux";
import {getContracts} from "../../ducks/status";

export const TREASURY_CONTRACT = '0x24c3235558572cff8054b5a419251d3b0d43e91b';
export const TOKEN_CONTRACT = '0x2e071D2966Aa7D8dECB1005885bA1977D6038A65';
export const REWARD_CONTRACT = '0xa4463f9ff0d87531232c8c4819b536c332da6eac';
export const SMART_CONTRACTS = [
    // top contract latest
    '0x3d47be8585c747218dadce3bee6fe18ee02f8a24',
    '0xa52e014b3f5cc48287c2d483a3e026c32cc76e6d',
    '0x048717ea892f23fb0126f00640e2b18072efd9d2',
    '0xddf0d0b9914d530e0b743808249d9af901f1bd01',
    '0x3ad2bfea535bf1673f22de61b19f758a2dd0efff',
    '0xD91E45416bfbBEc6e2D1ae4aC83b788A21Acf583',
    '0xEce701C76bD00D1C3f96410a0C69eA8Dfcf5f34E',
    '0x8F3d6447a647Ecf3c185ecbB165D2e6C41FAd547',
    '0xfaF4a5fbf621B049215C19a73baBd0B94fD9155F',
];

const TabContracts = () => {
    const gameContracts = useSelector(getContracts)
    const currentGameContractAdr = gameContracts[0]?.address
    return (
        <div id='tab-contracts'>
            <h3>
                Smart Contract Information
            </h3>
            <Contract
                readable={true}
                address={currentGameContractAdr}
                name='Current Etheroll smart contract'
            />
            <Contract
                readable={true}
                address={TREASURY_CONTRACT}
                name='Treasury contract'
            />
            <Contract
                readable={true}
                address={TOKEN_CONTRACT}
                name='Token contract'
            />
            <Contract
                address={REWARD_CONTRACT}
                name='Reward contract'
            />
            <Contract
                readable={true}
                address={TROLL_BOX_CONTRACT_ADR_PROD}
                name='MyEtheroll Troll Box Contract'
            />
            <OldContracts/>
        </div>
    )
};

const Contract = ({name, address, readable = false}) => (
    <div className="contract">
        {name &&
        <h4>
            {name}
        </h4>
        }
        <ContractsIcon/>
        <a
            href={"https://etherscan.io/address/" + address}
            target="_blank"
            rel="noopener noreferrer"
            className={`contract__adr ${readable && 'readable'}`}
        >
            {address}
        </a>
        {readable &&
        <a
            href={"https://etherscan.io/address/" + address + "#readContract"}
            target="_blank"
            rel="noopener noreferrer"
            className='contract__read'
        >
            read contract
        </a>
        }
    </div>
);

const OldContracts = () => {
    const gameContracts = useSelector(getContracts)
    return (
        <div className="old-contracts">
            <h3>
                Old Contracts
            </h3>
            {(gameContracts.length > 0 ? gameContracts : SMART_CONTRACTS).map((contract, idx) => {
                if (idx > 0) {
                    return (
                        <Contract
                            key={contract.address ? contract.address : contract}
                            address={contract.address ? contract.address : contract}
                        />
                    )
                }
                return null;
            })}
        </div>
    )
};

export default TabContracts;