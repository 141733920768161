import React from "react";

import './tab-troll-box.scss';
import {connect} from "react-redux";
import {getMessage, getMessages, isDataLoaded, loadTrollBoxMessages, setMessage} from "../../ducks/trollBox";
import Timer from "../util/Timer";
import AddressFormat from "../../util/formatters/address";
import {Link} from "react-router-dom";
import Linkify from 'react-linkify';
import {hasWeb3} from "../../util/web3";
import AddIcon from "mdi-react/MessagePlusIcon";
import KeyDownCatcher from "../util/KeyDownCatcher";
import {activatePopup} from "../../ducks/popup";
import CreateTransaction from "./CreateTransaction";
import TimeAgo from "../tab-rolls/TimeAgo";
import LoaderHorizontal from "../util/LoaderHorizontal";

class TabTrollBox extends React.Component {

    updateMessage = e => {
        const {setMessage} = this.props;
        setMessage(e.target.value);
    };

    openTransactionDialog = () => {
        const {activatePopup} = this.props;
        activatePopup(<CreateTransaction/>);
    };

    render() {
        const {loadTrollBoxMessages, messages, message, isDataLoaded} = this.props;
        return (
            <div id='tab-roll-box'>
                <Timer
                    action={loadTrollBoxMessages}
                />
                <h1>
                    Troll box
                </h1>

                {!hasWeb3() &&
                <div className="enable-ethereum-message">
                    If you want to post your message to MyEtheroll, please install <a href="https://metamask.io/"
                                                                                      rel="noopener noreferrer"
                                                                                      target="_blank">metamask</a> or
                    use an
                    ethereum enabled browser for mobile like <a href="https://www.toshi.org/" target="_blank"
                                                                rel="noopener noreferrer">toshi</a>.
                    <br/>
                    <br/>
                    May also work with other plugins/ browsers, but not tested.
                </div>
                }

                {hasWeb3() &&
                <div className='message-input'>
                    <KeyDownCatcher
                        onEnter={this.openTransactionDialog}
                    >
                        <input
                            placeholder='Your message here'
                            value={message}
                            onChange={this.updateMessage}
                            maxLength="100"
                        />
                    </KeyDownCatcher>
                    <button onClick={this.openTransactionDialog}>
                        <AddIcon/> add message
                    </button>
                </div>
                }

                {!isDataLoaded &&
                <LoaderHorizontal/>
                }

                <div className='messages'>
                    {messages.map(message => {
                        const {
                            sender: user,
                            message: text,
                            valid_from: validFrom,
                            valid_to: validTo,
                        } = message;
                        const duration = Math.round((validTo - validFrom) / 60);
                        return (
                            <div
                                className={`messages__message ${validFrom * 1000 > new Date() && 'pending'}`}
                                key={validTo}
                            >
                                <div className='messages__message__user'>
                                    <Link to={`/rolls/${user}`}>
                                        {AddressFormat.parseHashAddress(user)}
                                    </Link>
                                </div>
                                <div className='messages__message__time'>
                                    <TimeAgo
                                        timestamp={validFrom * 1000}
                                    /> — {duration} minute duration
                                </div>
                                <div className='messages__message__text'>
                                    <Linkify properties={{
                                        target: '_blank',
                                        rel: 'noopener noreferrer',
                                    }}>
                                        {text}
                                    </Linkify>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        messages: getMessages(state),
        message: getMessage(state),
        isDataLoaded: isDataLoaded(state),
    }
};

const mapDispatchToProps = {
    loadTrollBoxMessages,
    setMessage,
    activatePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabTrollBox);