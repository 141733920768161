import {getFromLocalStorage, saveToLocalStorage} from "../util/persistance";
import {DICE_TOKENS_TOTAL} from "../data/misc";

export const NAME_SPACE = 'holdings';

const SET_AMOUNT = `${NAME_SPACE}/SET_AMOUNT`;

export const setAmount = amount => {
  return {
    type: SET_AMOUNT,
    amount: amount,
  }
};

const initialState = {
  amount: getFromLocalStorage(`${NAME_SPACE}/amount`) || 10000
};

function holdingsReducer(state = initialState, action){
  switch (action.type){
    case SET_AMOUNT:
      saveToLocalStorage(`${NAME_SPACE}/amount`, action.amount);
      return {
        ...state
        , amount: action.amount
      };
    default:
      return state;
  }
}

function getState(state){
  return state[NAME_SPACE];
}

export function getAmount(state){
  return getState(state).amount;
}

export function getRatio(state){
  return getAmount(state) / DICE_TOKENS_TOTAL;
}

export default holdingsReducer;