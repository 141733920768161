import React from "react";
import {getHouseProfitQuarter} from "../../ducks/status";
import {getCurrency} from "../../ducks/currency";
import connect from "react-redux/es/connect/connect";
import Estimate from "../../data/estimate";

import './tab-estimate.scss';
import Counter from "../util/Counter";
import {getRatio} from "../../ducks/holdings";
import Quarters from "../../data/quarters";
import {numberFormat} from "../../util/formatters/numbers";
import {getEthOverDiceRate} from "../../ducks/rates";
import {DICE_TOKENS_TOTAL} from "../../data/misc";

class TabEstimate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      customPrice: 0.0025,
      CustomQuarterlyProfit: 2500,
      CustomYearlyProfit: 10000,
    }
  }

  render() {
    const {houseProfits, ratio, currency, price} = this.props;
    const estimate = Estimate.fromCurrentQuarter(houseProfits);
    const quarters = Quarters.getAll();
    const end = quarters[0].end;
    const negativeBalance = Quarters.getNegativeBalance();
    const estimateQuarter = estimate.estimateQuarter;
    const estimateYearly = estimate.estimateYearly;
    const expectedYield = price === 0 ? 0 : (estimateYearly / DICE_TOKENS_TOTAL) / price;
    return (
      <div id='tab-estimate'>
        <h1>
          Dividend estimate
        </h1>
        <div className='estimates'>
          <div className='estimates__estimate quarter'>
            <h2>
              This Quarter
            </h2>
            <h3>
              ends {new Date(end * 1000).toLocaleDateString()}
            </h3>
            <NumbersContainer
              estimate={estimateQuarter}
              currency={currency}
              ratio={ratio}
              header={'House profits'}
              reduction={negativeBalance}
            />
            {Math.abs(negativeBalance) > 0 &&
            <div className='negative-balance'>
              * Only profits exceeding the negative balance of {numberFormat(Math.abs(negativeBalance), 2)} eth will
              be paid out as dividends for this quarter.
            </div>
            }
          </div>
          <div className='estimates__estimate year'>
            <h2>
              Yearly
            </h2>
            <h3>
              &nbsp;
            </h3>
            <NumbersContainer
              estimate={estimateYearly}
              currency={currency}
              ratio={ratio}
            />
            <div className={'yield'}>
              <h4>
                Expected yield
              </h4>
              <Counter
                amount={expectedYield * 100}
                decimals={2}
                postfix={`%`}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const NumbersContainer = ({estimate, currency, ratio, reduction=0}) => (
  <div className='estimates__estimate__numbers'>
    <div className='estimates__estimate__numbers__number'>
      <h3>
        House profits
      </h3>
      <div className='ether'>
        <Counter
          amount={estimate}
          smartDecimals={true}
          postfix={' ETH'}
        />
      </div>
      <div className='fiat'>
        <Counter
          amount={estimate * currency.rate}
          decimals={currency.decimals}
          prefix={currency.prefix}
          postfix={currency.postfix}
        />
      </div>
    </div>
    <div className='estimates__estimate__numbers__number'>
      <h3>
        Your share
      </h3>
      <div className='ether'>
        <Counter
          amount={(estimate + reduction) * ratio}
          decimals={2}
          postfix={` ETH${reduction < 0 ? ' *' : ''}`}
        />
      </div>
      <div className='fiat'>
        <Counter
          amount={estimate * ratio * currency.rate}
          decimals={currency.decimals}
          prefix={currency.prefix}
          postfix={currency.postfix}
        />
      </div>
    </div>
  </div>
);


const mapStateToProps = state => {
  return {
    houseProfits: getHouseProfitQuarter(state),
    ratio: getRatio(state),
    currency: getCurrency(state),
    price: getEthOverDiceRate(state),
  }
};

export default connect(mapStateToProps)(TabEstimate);