import {getFromLocalStorage, saveToLocalStorage} from "../util/persistance";
import Notifications from "../data/notifications";

export const NAME_SPACE = 'notifications';

const UPDATE_LAST_READ = `${NAME_SPACE}/UPDATE_LAST_READ`;


export function updateLastRead() {
  return {
    type: UPDATE_LAST_READ,
  };
}

const initialState = {
  lastReadTime: getFromLocalStorage(`${NAME_SPACE}/readId`) || 0,
};


function notifications(state = initialState, action) {
  switch (action.type) {
    case UPDATE_LAST_READ:
      const lastNotification = Notifications.getLatestMessage();
      saveToLocalStorage(`${NAME_SPACE}/readId`,lastNotification.start);
      return {
        ...state,
        lastReadTime: lastNotification.start
      };
    default:
      return state
  }
}

export function getState(state) {
  return state[NAME_SPACE];
}

export function getLastReadId(state) {
  return parseInt(getState(state).lastReadTime);
}

export function hasUnreadNotification(state){
  const readId = getLastReadId(state);
  const lastNotification = Notifications.getLatestMessage();
  return lastNotification && lastNotification.start > readId;
}

export function getActiveNotification(){
  return Notifications.getLatestMessage();
}

export function hasActiveNotification(){
  return !!getActiveNotification();
}

export default notifications;