import React from "react";
import './dividend-payout-warning.scss'
import {useSelector} from "react-redux";
import {getIsDividendPayoutActive} from "../../ducks/status";
import AlertCircleOutlineIcon from "mdi-react/AlertCircleOutlineIcon";

const DividendPayoutWarning = () => {
    const isDividendPayoutActive = useSelector(getIsDividendPayoutActive)
    return isDividendPayoutActive && (
        <div className='dividend-payout-warning'>
            <a href="https://rewards.etheroll.com/#tab5" target="_blank" rel="noopener noreferrer">
                <AlertCircleOutlineIcon/>
                Click here to claim dividends!
            </a>
        </div>
    )
}

export default DividendPayoutWarning