import React from "react";
import './date-range.scss';
import {Range} from 'rc-slider';
import 'rc-slider/assets/index.css';
import {getDateFrom, getDateTo, getHistory, getTotalHistoryLength, setDateFrom, setDateTo} from "../../ducks/stats";
import oldStats from './oldStats.json'
import {connect} from "react-redux";

class DateRange extends React.Component {

  handleChange = e => {
    const {setDateFrom, setDateTo} = this.props;
    setDateFrom(e[0] * -1);
    setDateTo(e[1] * -1);
  };

  getDate = i => {
    const {history} = this.props;
    const totalHistory = [...history, ...oldStats];
    if(i === 0){
      return 'Last 24h';
    }
    const date = totalHistory[i];
    return date ? date.date : '';
  };

  render(){
    const {dateFrom, dateTo, totalHistoryLength} = this.props;
    const min = (totalHistoryLength - 1 )* -1;
    return (
      <div id="stats-date-range" className='no-select'>
        <span className='from-date no-select'>
          {this.getDate(dateFrom)}
        </span>
        <Range
          min={min}
          max={0}
          value={[dateFrom * -1, dateTo * -1]}
          onChange={this.handleChange}
        />
        <span className='to-date no-select'>
          {this.getDate(dateTo)}
        </span>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    history: getHistory(state),
    totalHistoryLength: getTotalHistoryLength(state),
    dateFrom: getDateFrom(state),
    dateTo: getDateTo(state),
  }
};

const mapDispatchToProps = {
  setDateFrom,
  setDateTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(DateRange);