import {fetchStatus} from "../services/statusAPI";
import {getFromLocalStorage, saveToLocalStorage} from "../util/persistance";
import Quarters from "../data/quarters";
import {DAY, getNow, WEEK} from "../util/formatters/timeAndDate";

export const NAME_SPACE = 'status';

const LOAD_BALANCE_PENDING = `${NAME_SPACE}/LOAD_BALANCE_PENDING`;
const LOAD_BALANCE_FAIL = `${NAME_SPACE}/LOAD_BALANCE_FAIL`;
const LOAD_BALANCE_SUCCESS = `${NAME_SPACE}/LOAD_BALANCE_SUCCESS`;

function loadBalancePending(){
  return {
    type: LOAD_BALANCE_PENDING
  }
}

function loadBalanceFail(error){
  return {
    type: LOAD_BALANCE_FAIL,
    error: error
  }

}

function loadBalanceSuccess(response){
  if(!response.data.balance){
    return loadBalanceFail('WS error...')
  }
  return {
    type: LOAD_BALANCE_SUCCESS,
    data: response.data
  }
}

export function loadBalance(){
  return function (dispatch){
    dispatch(loadBalancePending());
    fetchStatus().then(
        response => dispatch(loadBalanceSuccess(response)),
        error => dispatch(loadBalanceFail(error)),
      )
  }
}

const initialState = {
    isLoading: false
  , isDataLoaded: false
  , error: null
  , houseBalance: 5000
  , baseBankroll: 5000
  , withDrawnProfit: 3596.31
  , bets24h: 0
  , ethWagered24h: 0
  , houseProfit24h: 0
  , players24h: 0
  , trollBoxMessages: []
  , gameIsPaused: false
  , nextFreeze: 0
  , nextThaw: 0
  , gameContracts: []
  , uniswap10xBuyRate: 0
  , uniswap10xSellRate: 0
  , ...getFromLocalStorage(`${NAME_SPACE}/status`)
};

function statusReducer(state = initialState, action){
  switch(action.type){
    case LOAD_BALANCE_PENDING:
      return {
        ...state
        , isLoading: true
      };
    case LOAD_BALANCE_FAIL:
      return {
        ...state
        , isLoading: false
        , isDataLoaded: true
        , error: action.error
      };
    case LOAD_BALANCE_SUCCESS:
      const data = action.data;
      const persisted = {
          houseBalance: data.balance
        , baseBankroll: data.base_bankroll
        , gameIsPaused: data.game_is_paused
        , nextFreeze: data.next_freeze
        , nextThaw: data.next_thaw
        , withDrawnProfit: data.withdrawn_profit
        , bets24h: data['24h_bets']
        , ethWagered24h: data['24h_eth_wagered']
        , houseProfit24h: data['24h_house_profit']
        , players24h: data['24h_players']
        , gameContracts: data.gameContracts
        , uniswap10xBuyRate: data.uniswap10x_buy_rate
        , uniswap10xSellRate: data.uniswap10x_sell_rate
        // , uniswap10xBuyRate: 0.002565
        // , uniswap10xSellRate: 0.002665
        , trollBoxMessages: [...data.tickerMessages]
      };
      saveToLocalStorage(`${NAME_SPACE}/status`,persisted);
      return {
        ...state
        , isLoading: false
        , isDataLoaded: true
        , ...persisted
      };
    default:
      return state;
  }
}

function getState(state){
  return state[NAME_SPACE];
}

export function getBalance(state){
  return getState(state).houseBalance
}

export function getBaseBankroll(state){
  return getState(state).baseBankroll
}

export function getWithDrawnProfit(state){
  return getState(state).withDrawnProfit
}

export function getHouseProfit(state){
  return getBalance(state) - getBaseBankroll(state)
}

export function getHouseProfitQuarter(state){
  return getBalance(state) - getBaseBankroll(state) - Quarters.getNegativeBalance();
}

export function getHouseProfitTotal(state){
  return getBalance(state) - getBaseBankroll(state) + getWithDrawnProfit(state)
}

export function getBets24h(state){
  return getState(state).bets24h;
}

export function getWagered24h(state){
  return getState(state).ethWagered24h;
}

export function getHouseProfit24h(state){
  return getState(state).houseProfit24h;
}

export function getPlayers24h(state){
  return getState(state).players24h;
}

export function isDataLoaded(state){
  return getState(state).isDataLoaded;
}

export function getTrollBoxMessages(state){
  return getState(state).trollBoxMessages;
}

export const getIsGamePaused = state => getState(state).gameIsPaused

export const getNextFreeze = state => getState(state).nextFreeze
export const getNextThaw = state => getState(state).nextThaw
export const getIsFreezeWithinTheWeek = state => !getIsDividendPayoutActive(state) && getNextFreeze(state) < getNow() + WEEK
export const getIsFreezeWithinTheDay = state => !getIsDividendPayoutActive(state) && getNextFreeze(state) < getNow() + DAY
export const getIsThawWithinTheDay = state => getIsDividendPayoutActive(state) && getNextThaw(state) < getNow() + DAY
export const getIsDividendPayoutActive = state =>
    getNow() > getNextFreeze(state) &&
    getNow() < getNextThaw(state)


export const getContracts = state => getState(state).gameContracts

export const getUniswap10xBuyRate = state => getState(state).uniswap10xBuyRate
export const getUniswap10xSellRate = state => getState(state).uniswap10xSellRate

export default statusReducer;