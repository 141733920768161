import React from "react";

import './currency.scss';
import {CURRENCIES, getCurrency, setCurrency} from "../../ducks/currency";
import connect from "react-redux/es/connect/connect";
import {closePopup} from "../../ducks/popup";

import SelectedIcon from "mdi-react/CheckIcon";

class Currency extends React.Component {

  chooseCurrency = code => {
    const {setCurrency, closePopup = ()=>{}} = this.props;
    setCurrency(code);
    closePopup();
  };

  render(){
    const {currency: selected} = this.props;
    return(
      <div id='currency'>
        <h2>
          Preferred Currency
        </h2>
        <div className='currency-list'>
          {Object.keys(CURRENCIES).map(code => {
            const currency = CURRENCIES[code];
            const isSelected = selected.code === code;
            return (
              <div
                key={code}
                className={`currency-list__currency ${isSelected && 'selected'}`}
                tabIndex={0}
                onClick={() => this.chooseCurrency(code)}
              >
                {isSelected && <SelectedIcon />}
                {currency.prefix} {currency.name}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

}


const mapStateToProps = state =>{
  return {
    currency: getCurrency(state),
  }
};

const mapDispatchToProps = {
  setCurrency,
  closePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Currency);