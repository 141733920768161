export function numberFormat(number, decimals) {
    if (decimals === -1) {
        if (Math.abs(number) > 100000) {
            number = (Math.round(number / 10000) / 100) + 'M'
        } else if (Math.abs(number) > 1000) {
            number = (Math.round(number / 10) / 100) + 'K'
        } else {
            number = Math.round(number)
        }
        return number
    }
    return number_format(number, decimals, '.', ',')
}

export function number_format(number, decimals, decPoint, thousandsSep) {
    number = (number + '')
        .replace(/[^0-9+\-Ee.]/g, '')
    var n = !isFinite(+number) ? 0 : +number
    var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
    var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
    var s = ''
    var toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec)
        return '' + (Math.round(n * k) / k)
            .toFixed(prec)
    }
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
        .split('.')
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || '')
        .length < prec) {
        s[1] = s[1] || ''
        s[1] += new Array(prec - s[1].length + 1)
            .join('0')
    }
    return s.join(dec)
}

export function round(number, decimalPoints){
  return Math.round(number * Math.pow(10, decimalPoints)) / Math.pow(10, decimalPoints)
}