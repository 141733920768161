import React, {useEffect} from 'react';
import Trade from './Trade.js';
import LoaderHorizontal from "../../util/LoaderHorizontal";
import {LIQUIDITY_CONTRACT_ADDRESS} from "../../../util/liquidity-contract/liquidity_contract";

const TradeList = ({trades}) => {
  useEffect(()=>{
    if(window.location.hostname === 'localhost'){
      const abc = trades.filter(trade => trade.timeStamp >= 1565603435);
      let diceRevenue = 0;
      let ethRevenue = 0;
      abc.forEach(trade => {
        const isBuy = trade.seller === LIQUIDITY_CONTRACT_ADDRESS;
        if(isBuy){
          diceRevenue += trade.dice;
        } else {
          ethRevenue += trade.eth;
        }
      });
      console.log("eth rev", ethRevenue);
      console.log("0.6% = ", ethRevenue * .006);
      console.log("dice rev", diceRevenue);
      console.log("0.6% = ", diceRevenue * .006);
    }
  },[trades]);
  if (trades.length === 0) {
    return (
      <LoaderHorizontal/>
    )
  }
  return (
    <table>
      <thead>
        <tr>
          <th colSpan="100%" className="header">
            Most recent trades
          </th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th>
            User
          </th>
          <th>
            Type
          </th>
          <th>
            Time
          </th>
          <th>
            DICE
          </th>
          <th>
            ETH
          </th>
          <th>
            Rate
          </th>
        </tr>
      </thead>
      <tbody>
      {trades.map(trade => (
        <Trade
          key={trade.hash}
          trade={trade}
        />
      ))}
      </tbody>
    </table>
  );
};


export default TradeList;