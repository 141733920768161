import axios from "axios/index";

export function fetchRates(){

  const url = 'https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD,EUR,GBP,BTC,CHF,DICE&extraParams=MyEtheroll';

  return axios.get(url)
    .then(response => {
      return response
    })
    .catch(error => {
      throw new Error(error)
    })

}

export function fetchRateChange(){

  const url = 'https://min-api.cryptocompare.com/data/histohour?fsym=DICE&tsym=ETH&limit=24&aggregate=1&extraParams=MyEtheroll';

  return axios.get(url)
    .then(response => {
      return transformHistoryFromWS(response)
    })
    .catch(error => {
      return error
    })

}

function transformHistoryFromWS(response) {
  const data = response.data.Data;
  const open = data[0].open;
  const close = data[data.length-1].close;
  return {
    open, close
  };
}