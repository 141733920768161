import * as React from "react";
import {readableDate} from "../../util/formatters/timeAndDate";

class TimeAgo extends React.PureComponent {

  constructor(props){
    super(props);
    this.timeOut = 0;
  }

  componentDidMount(){
    this.timeOut = setTimeout(this.refresh, this.calculateTimer());
  }

  componentWillUnmount(){
    clearTimeout(this.timeOut);
  }

  calculateTimer = () => {
    const {timestamp} = this.props;
    const diff = (Date.now() - timestamp) / 1000;
    if (diff < 60 * 5) { // minute
      return 1000; // every second
    }
    if (diff < 60 * 60) {
      return 30 * 1000; // every 30 seconds
    }
    if (diff < 60 * 60 * 24) {
      return 30 * 60 * 1000; // every 30 minutes
    }
    return 60 * 60 * 1000; // every hour
  };

  refresh = () => {
    this.forceUpdate();
    this.timeOut = setTimeout(this.refresh, this.calculateTimer());
  };

  render(){
    const {timestamp} = this.props;
    const dateString = readableDate(timestamp, false);
    const dateStringParts = dateString.split(' ');
    const localeDateString = new Date(timestamp).toLocaleString();
    return(
      <>
        <div
            className={'long'}
            title={localeDateString}
        >
          {dateString} ago
        </div>
        <div
            className={'short'}
            title={localeDateString}
        >
          {dateStringParts[0]+dateStringParts[1].substr(0,1)}
        </div>
      </>
    )
  }

}

export default TimeAgo;