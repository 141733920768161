import React from "react";

import {activatePopup} from "../../ducks/popup";
import {connect} from "react-redux";
import Currency from "../settings/Currency";

import './footer.scss';
import {getCurrency} from "../../ducks/currency";

import EditIcon from "mdi-react/EditIcon";

class Footer extends React.Component {

  openCurrencyPopup = () => {
    const {activatePopup} = this.props;
    activatePopup(<Currency/>);
  };

  render(){
    const {currency} = this.props;
    return(
      <footer>
        <div
          className='currency-of-choice'
          onClick={this.openCurrencyPopup}
          tabIndex={0}
        >
          Currency of choice set to: {currency.prefix} {currency.name} <EditIcon />
        </div>
        <div>
          ETH Donation Address: <a href="https://etherscan.io/address/0x7e3dc9f40e7ff9db80c3c7a1847cb95f861b3aef" target="_blank" rel="noopener noreferrer" >0x7e3dc9f40e7ff9db80c3c7a1847cb95f861b3aef</a> ❤
        </div>
        <div>
          Data from <a href="https://etherscan.io" target="_blank" rel="noopener noreferrer" >etherscan.io</a> and <a href="https://cryptocompare.com" target="_blank" rel="noopener noreferrer" >cryptocompare.com</a>
        </div>
        <div>
          Contact: <a href="https://reddit.com/user/myetheroll" target="_blank" rel="noopener noreferrer" >reddit.com/u/myetheroll</a>
        </div>
      </footer>
    )
  }

}

const mapStateToProps = state =>{
  return {
    currency: getCurrency(state),
  }
};

const mapDispatchToProps = {
  activatePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);