import React from "react";
import connect from "react-redux/es/connect/connect";
import {
  get24HourActivity,
  getDateFrom,
  getDateTo,
  getHistory,
  getOptions,
  isDataLoaded,
  loadStats
} from "../../ducks/stats";
import Timer from "../util/Timer";
import LoaderHorizontal from "../util/LoaderHorizontal";
import StatsDataFactory from "./StatsDataFactory";

import './tab-stats.scss';
import {Chart, Line} from "react-chartjs-2";
import {getHouseProfitTotal} from "../../ducks/status";
import {verticalLinePlugin} from "./chartJsPlugins";
import Options from "./Options";
import DateRange from "./DateRange";

class TabStats extends React.Component {


  componentDidMount() {
    Chart.plugins.register(verticalLinePlugin)
  }

  render(){

    const {
      isDataLoaded,
      loadStats,
      houseProfitTotal,
      history,
      last24hours,
      options,
      dateFrom,
      dateTo,
    } = this.props;

    const dataSets = StatsDataFactory.createDataSets(history, last24hours, houseProfitTotal, options, dateFrom, dateTo);
    const graphOptions = StatsDataFactory.createOptions(options, dataSets.lineAtIndex);

    return (
      <div id='tab-stats'>

        <Timer
          action={loadStats}
          interval={30}
        />

        {!isDataLoaded &&
        <LoaderHorizontal/>
        }

        {isDataLoaded &&
        <DateRange/>
        }

        {isDataLoaded &&
        <Line
          data={dataSets}
          options={graphOptions}
          className='noselect'
        />
        }

        {(isDataLoaded || true) &&
        <Options/>
        }

      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    history: getHistory(state),
    last24hours: get24HourActivity(state),
    isDataLoaded: isDataLoaded(state),
    houseProfitTotal: getHouseProfitTotal(state),
    options: getOptions(state),
    dateFrom: getDateFrom(state),
    dateTo: getDateTo(state),
  }
};

const mapDispatchToProps = {
  loadStats,
};

export default connect(mapStateToProps, mapDispatchToProps)(TabStats);