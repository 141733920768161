import React from 'react';
import Counter from "../../util/Counter";
import {createBuyTokenTransaction} from "../../../util/liquidity-contract/liquidity_contract";
import {addTransaction} from "../../../ducks/liquidityContract";
import {useDispatch} from "react-redux";

const TradeDialogBuy = ({amountToBuy, buyPayout, ethBalance, changeAmountToBuy, disabled, setErrorMessage}) => {

    const dispatch = useDispatch()

    const buyTokens = async () => {
        if (ethBalance < amountToBuy) {
            setErrorMessage('Insufficient funds')
            return
        }
        try {
            const transactionHash = await createBuyTokenTransaction(amountToBuy)
            dispatch(addTransaction(transactionHash))
            setErrorMessage()
        } catch (error) {
            setErrorMessage(error.message)
        }
    }

    const rate = buyPayout !== 0 ? amountToBuy / buyPayout : 0;

    return (
        <div className='rates__rate buying'>

            <h2>
                Buying
            </h2>

            <div className='amount'>
                <div className=''>
                    Specify below how much ETH you wish to spend
                </div>
                <input
                    type='number'
                    onChange={changeAmountToBuy}
                    value={amountToBuy}
                />
                <button
                    disabled={disabled} onClick={buyTokens}
                    title={disabled ? 'Trading currently disabled' : null}
                >
                    BUY
                </button>
            </div>
            <div>
                <div className='payout'>
                    Payout:
                    <Counter
                        amount={buyPayout !== Infinity ? buyPayout : 0}
                        smartDecimals={true}
                        postfix={' DICE'}
                    />
                </div>
                <div className='rate'>
                    Rate:
                    <Counter
                        amount={rate || 0}
                        postfix={' DICE/ETH'}
                        decimals={6}
                    />
                </div>
                <div className='balance'>
                    Your balance:
                    <Counter
                        amount={ethBalance || 0}
                        postfix={' ETH'}
                        decimals={2}
                    />
                </div>
            </div>

        </div>
    )
};

export default TradeDialogBuy;