import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getTransactions} from "../../../ducks/liquidityContract";
import {activatePopup} from "../../../ducks/popup";
import TransactionStatus from "./TransactionStatus";


const Transactions = () => {
    const dispatch = useDispatch();
    const transactions = useSelector(getTransactions);
    if(transactions.length===0)return null;
    return (
        <div className='transactions'>
            <h2>
                Transaction added
            </h2>
            {transactions.map(transaction=>
                <div key={transaction} className={'transactions__transaction'}>
                    <button onClick={()=>
                        dispatch(activatePopup(
                            <TransactionStatus hash={transaction} />
                        ))
                    }>
                        {transaction}
                    </button>
                </div>
            )}
        </div>
    )
};

export default Transactions;