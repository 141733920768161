import React from 'react';
import Card from "./Card";
import {ToolTip} from "../util/ToolTip";
import EthereumIcon from "mdi-react/EthereumIcon";
import NumberUpIcon from "mdi-react/ArrowUpIcon";
import NumberDownIcon from "mdi-react/ArrowDownIcon";
import StackIcon from "mdi-react/SackIcon";
import DiceIcon from "mdi-react/CoinsIcon";
import EditIcon from "mdi-react/PencilOutlineIcon";
import SaveIcon from "mdi-react/PencilOutlineIcon";
import {getRate} from "../../ducks/rates";
import connect from "react-redux/es/connect/connect";
import {getAmount, setAmount} from "../../ducks/holdings";
import Counter from "../util/Counter";
import KeyDownCatcher from "../util/KeyDownCatcher";
import ClickOutsideCatcher from "../util/ClickOutsideCatcher";
import {getDiff} from "../../ducks/rateChange";
import SeeIcon from "mdi-react/ArrowRightCircleIcon";
import {useSelector} from "react-redux";
import {getIsDividendPayoutActive, getUniswap10xBuyRate, getUniswap10xSellRate} from "../../ducks/status";
import {numberFormat} from "../../util/formatters/numbers";

const DICE_INTRINSIC_VALUE = 0.0025

class CardYourStack extends React.Component {

    state = {
        editMode: false
    }

    handleChange = e => {
        const amount = e.target.value;
        const {setAmount} = this.props;
        setAmount(amount)
    };

    toggleEditMode = () => {
        this.setState({
            editMode: !this.state.editMode
        });
    };

    render() {
        const {diceAmount, diceRate, diceRateChange} = this.props;
        const {editMode} = this.state;
        return (
            <Card className='your-stack'>
                <div className='card__icon-background'>
                    <StackIcon/>
                </div>
                <h4>
                    <StackIcon/>
                    Your stack
                </h4>
                {editMode ?
                    <EditMode
                        diceAmount={diceAmount}
                        handleChange={this.handleChange}
                        toggleEditMode={this.toggleEditMode}
                    />
                    :
                    <>
                        <DataPoints
                            diceAmount={diceAmount}
                            diceRate={diceRate}
                            diceRateChange={diceRateChange}
                            toggleEditMode={this.toggleEditMode}
                        />
                        <BuyTokens />
                    </>
                }
            </Card>
        )
    }
}

const DataPoints = ({diceAmount, diceRate, diceRateChange, toggleEditMode}) => (
    <KeyDownCatcher
        onEnter={toggleEditMode}
        onSpace={toggleEditMode}
    >
        <div className='your-stack__data-points'>
            <div className='your-stack__data-points__data-point'>
                <ToolTip text={`Value of your ÐICE in ETH`} setRight={true}>
                    <div className='your-stack__data-points__data-point__main-value'>
                        <EthereumIcon/>
                        <Counter
                            amount={diceRate * diceAmount}
                            smartDecimals={true}
                        />
                    </div>
                    <div className='your-stack__data-points__data-point__sub-value'>
                        {diceRateChange < 0 ?
                            <NumberDownIcon className='icon_negative'/> :
                            <NumberUpIcon className='icon_positive'/>
                        }
                        <Counter
                            amount={diceAmount * diceRateChange}
                            absolute={true}
                            smartDecimals={true}
                        />
                    </div>
                </ToolTip>
            </div>
            <div className='your-stack__data-points__data-point dice-amount' tabIndex={0}>
                <ToolTip
                    text={`Amount of ÐICE tokens you hold`}
                    setRight={true}
                    onClick={toggleEditMode}
                >
                    <div className='your-stack__data-points__data-point__main-value'>
                        <DiceIcon/>
                        <Counter
                            amount={Number(diceAmount)}
                            smartDecimals={true}
                        />
                    </div>
                    <div className='your-stack__data-points__data-point__sub-value edit'>
                        <EditIcon className='edit_icon'/>
                        edit
                    </div>
                </ToolTip>
            </div>
        </div>
    </KeyDownCatcher>
);

const EditMode = ({diceAmount, handleChange, toggleEditMode}) => (
    <KeyDownCatcher
        onEnter={toggleEditMode}
        onEsc={toggleEditMode}
    >
        <ClickOutsideCatcher onCatch={toggleEditMode}>
            <div className='your-stack__edit-amount'>
                <div className='your-stack__edit-amount__text'>
                    Input the amount of ÐICE tokens you currently hold to personalize calculations.
                </div>
                <input
                    type='number'
                    value={diceAmount}
                    onChange={handleChange}
                />
                <div
                    className='button'
                    onClick={toggleEditMode}
                    tabIndex={0}
                >
                    <SaveIcon/>
                </div>
            </div>
        </ClickOutsideCatcher>
    </KeyDownCatcher>
);

const Uniswap10xLink = ({children = 'buy tokens', isBuying = true}) => {
    const buyLink = '/swap?inputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&outputCurrency=0x2e071D2966Aa7D8dECB1005885bA1977D6038A65&exactAmount=1'
    const sellLink = '/swap?inputCurrency=0x2e071D2966Aa7D8dECB1005885bA1977D6038A65&outputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&exactAmount=500'
    return (
        <a
            href={isBuying ? buyLink : sellLink}
            target="_blank"
            rel="noopener noreferrer"
        >
            {children}
            <SeeIcon/>
        </a>
    )
}

const BuyTokens = () => {
    const buyRate = useSelector(getUniswap10xBuyRate)
    const sellRate = useSelector(getUniswap10xSellRate)
    // const cryptoCompareRate = useSelector(getEthOverDiceRate)
    const cryptoCompareRate = DICE_INTRINSIC_VALUE

    const uniswap10xIsCheap = buyRate * 1.025 < cryptoCompareRate
    const uniswap10xIsExpensive = sellRate / 1.075 > cryptoCompareRate

    const isTokensFrozen = useSelector(getIsDividendPayoutActive)

    return isTokensFrozen ?
        <div className='your-stack__data-points__data-point__sub-value exchanges'>
            <span className='frozen'>
                Tokens currently frozen
            </span>
        </div>
            :
        <div className='your-stack__data-points__data-point__sub-value exchanges'>
            {/*<Link to='exchanges'>*/}
            {/*    buy tokens*/}
            {/*    <SeeIcon/>*/}
            {/*</Link>*/}
            {!uniswap10xIsCheap && !uniswap10xIsExpensive &&
            <Uniswap10xLink>
                Buy DICE/ETH at {numberFormat(buyRate, 6)}
            </Uniswap10xLink>
            }
            {uniswap10xIsCheap &&
            <Uniswap10xLink>
                Buy cheap <strong>{numberFormat(buyRate, 6)}</strong> DICE/ETH
            </Uniswap10xLink>
            }
            {uniswap10xIsExpensive && !uniswap10xIsCheap &&
            <Uniswap10xLink isBuying={false}>
                Sell expensive <strong className='expensive'>{numberFormat(sellRate, 6)}</strong> DICE/ETH
            </Uniswap10xLink>
            }
        </div>
}

const mapStateToProps = state => {
    return {
        rate: getRate(state),
        diceRate: getUniswap10xBuyRate(state),
        diceRateChange: getDiff(state),
        diceAmount: getAmount(state),
    }
};

const mapDispatchToProps = {
    setAmount
};

export default connect(mapStateToProps, mapDispatchToProps)(CardYourStack);