import React from 'react';
import {Link} from "react-router-dom";
import {LIQUIDITY_CONTRACT_ADDRESS} from "../../../util/liquidity-contract/liquidity_contract";
import QRCode from 'qrcode.react';

const Web3Missing = () => {
    return (
        <div className='web3-missing'>
            <h3>
                To buy DICE, simply send any amount of ETH to the following Ethereum address:
            </h3>
            <p>
                <span className='buy-contract-adr'>
                    <span className='buy-contract-adr__qr'>
                        <QRCode value={LIQUIDITY_CONTRACT_ADDRESS} size={160}/>
                    </span>
                    <span className='buy-contract-adr__adr'>
                        <a
                            href={`https://etherscan.io/address/${LIQUIDITY_CONTRACT_ADDRESS}#code`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {LIQUIDITY_CONTRACT_ADDRESS}
                        </a>
                        <br/>
                        <br/>
                        <b>Note: </b> Please don't spend large amounts (more than 1 ETH).<br />
                        Due to limited funds in the Contract, large orders might become very expensive.
                    </span>
                </span>
            </p>
            <p>
                If you want to sell DICE tokens using the Liquidity Contract, please install <a
                href="https://metamask.io/" target="_blank" rel="noopener noreferrer">metamask</a> or use an
                ethereum/ web3-enabled browser for mobile like <a href="https://www.toshi.org/" target="_blank"
                                                            rel="noopener noreferrer">toshi</a>.<br/>
                May also work with other plugins/ browsers, but not tested..
            </p>
            <p>
                Please do not send DICE tokens directly to the contract. They will be stuck.
            </p>
            <p>
                For other trading options please check out the <Link to='exchanges'>list of exchanges</Link>.
            </p>
        </div>
    )
};

export default Web3Missing;