export const SEC = 1
export const MIN = 60 * SEC
export const HOUR = 60 * MIN
export const DAY = 24 * HOUR
export const WEEK = 7 * DAY


export function readableDate(timestamp){
  let seconds = (Date.now() - timestamp) / 1000;
  if(seconds < 0)seconds = 0;
  return readableSeconds(seconds, seconds >= 60);
}

export function readableSeconds(seconds, ignoreSeconds = true){

  const months = Math.floor(seconds / 2592000);
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds / 60);
  const remainder = Math.floor(seconds % 60);

  const addS = n => n !== 1 ? 's' : '';

  if(minutes < 1){
    if(ignoreSeconds){
      return 'less than one minute';
    } else {
      return Math.floor(seconds) + ' seconds';
    }
  }
  if(months >= 1){
    return Math.floor(months) + ' month' + addS(months);
  }
  if(days >= 1){
    return Math.floor(days) + ' day' + addS(days);
  }
  if(hours >= 1){
    return Math.floor(hours) + ' hour' + addS(hours);
  }

  if(ignoreSeconds)return minutes + ' minute' + addS(minutes);

  return minutes + ' minute' + addS(minutes)+', ' + remainder + ' seconds';

}

export const getTimeDiff = (start, end) => {
  const diffMS = start > end ? 0 : end - start
  const diffInSeconds = diffMS / 1000

  const diffPartDays = Math.floor(diffInSeconds / DAY)
  const diffPartHours = Math.floor((diffInSeconds - (DAY * diffPartDays)) / HOUR)
  const diffPartMinutes = Math.floor((diffInSeconds - (DAY * diffPartDays) - (HOUR * diffPartHours)) / MIN)
  const diffPartSeconds = Math.floor((diffInSeconds - (DAY * diffPartDays) - (HOUR * diffPartHours) - (MIN * diffPartMinutes)) / SEC)

  return {
    diffInSeconds,
    diffPartDays,
    diffPartHours,
    diffPartMinutes,
    diffPartSeconds,
  }
}

export const getNow = () => Date.now() / 1000