import {getFromLocalStorage, saveToLocalStorage} from "../util/persistance";
import {fetchRates} from "../services/ratesAPI";
import {getUniswap10xBuyRate} from "./status";


export const NAME_SPACE = 'rates';

const LOAD_RATES_PENDING = `${NAME_SPACE}/LOAD_RATES_PENDING`;
const LOAD_RATES_FAIL = `${NAME_SPACE}/LOAD_RATES_FAIL`;
const LOAD_RATES_SUCCESS = `${NAME_SPACE}/LOAD_RATES_SUCCESS`;

function loadRatesPending() {
  return {
    type: LOAD_RATES_PENDING
  }
}

function loadRatesFail(error) {
  return {
    type: LOAD_RATES_FAIL,
    error: error
  }

}

function loadRatesSuccess(response) {
  if(!response.data.USD){
    return loadRatesFail('Cryptocompare error...');
  }
  return {
    type: LOAD_RATES_SUCCESS,
    rates: response.data
  }
}

export function loadRates() {
  return function (dispatch) {
    dispatch(loadRatesPending());
    fetchRates().then(
      response => dispatch(loadRatesSuccess(response)),
      error => dispatch(loadRatesFail(error)),
    )
  }
}


const initialState = {
  isLoading: false
  , isDataLoaded: false
  , error: null
  , rates: {
    USD: 125.62
    , EUR: 109.73
    , GBP: 97.74
    , BTC: 0.03347
    , CHF: 123.37
    , DICE: 344.59
    , ...getFromLocalStorage(`${NAME_SPACE}/rates`)
  }
};

function ratesReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_RATES_PENDING:
      return {
        ...state
        , isLoading: true
      };
    case LOAD_RATES_FAIL:
      return {
        ...state
        , isLoading: false
        , isDataLoaded: true
        , error: action.error
      };
    case LOAD_RATES_SUCCESS:
      const rates = action.rates;
      const persisted = rates;
      saveToLocalStorage(`${NAME_SPACE}/rates`, persisted);
      return {
        ...state
        , isLoading: false
        , isDataLoaded: true
        , rates: persisted
      };
    default:
      return state;
  }
}

function getState(state) {
  return state[NAME_SPACE];
}

export function getRate(state){
  return getRateFor(state, 'USD');
}

export function getRateFor(state, currency) {
  return getState(state).rates[currency] || 0;
}

export function getEthOverDiceRate(state){
  return getUniswap10xBuyRate(state)
  // const diceRate = getRateFor(state, 'DICE');
  // return diceRate === 0 ? 0 : 1 / diceRate;
}


export default ratesReducer;