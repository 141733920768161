import React from 'react';
import Card from "./Card";
import {ToolTip} from "../util/ToolTip";
import NumberDownIcon from "mdi-react/ArrowDownIcon";
import RatesIcon from "mdi-react/BankIcon";
import {getHouseProfitQuarter, isDataLoaded} from "../../ducks/status";
import connect from "react-redux/es/connect/connect";
import NumberUpIcon from "mdi-react/ArrowUpIcon";
import Quarters from "../../data/quarters";
import Counter from "../util/Counter";
import {getCurrency} from "../../ducks/currency";
import LoaderCircle from "../util/LoaderCircle";

const CardHouseProfit = ({houseProfits, currency, isDataLoaded}) => {
    return (
        <Card className='house-profit'>
            <div className='card__icon-background'>
                <RatesIcon rotate={10}/>
            </div>
            <h4>
                <RatesIcon rotate={10}/>
                House profits {Quarters.getCurrentQuarter().name}
            </h4>
            <ToolTip text={`House profit/ loss in ETH for current quarter`}>
                <div className='house-profit__eth'>
                    {houseProfits >= 0 ?
                        <NumberUpIcon className='icon_positive'/> :
                        <NumberDownIcon className='icon_negative'/>}
                    <Counter
                        amount={houseProfits}
                        absolute={true}
                        decimals={2}
                    />
                </div>
                <div className='house-profit__currency'>
                    <Counter
                        amount={houseProfits * currency.rate}
                        absolute={true}
                        decimals={currency.decimals}
                        prefix={currency.prefix}
                        postfix={currency.postfix}
                    />
                </div>
            </ToolTip>
            {!isDataLoaded && <LoaderCircle />}
        </Card>
    )
};


const mapStateToProps = state => {
    return {
        houseProfits: getHouseProfitQuarter(state),
        currency: getCurrency(state),
        isDataLoaded: isDataLoaded(state),
    }
};

export default connect(mapStateToProps)(CardHouseProfit);