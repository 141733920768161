import ABI from './abi.json';
import {enableWeb3, ethToWei, getWeb3, hasWeb3, resolveOnHash, weiToEth} from "../web3";

export const TROLL_BOX_CONTRACT_ADR_PROD = '0xe5c2aea81aca4e863b2b48374830c7cd70217aa7';

// export const TROLL_BOX_CONTRACT_ADR_TEST = '0xfd77daf2a6c98d51f3167e9471c8052409ca8970';

export const getTrollBoxState = async () => {
    if (hasWeb3()) {

        await enableWeb3()
        const web3 = getWeb3()

        const contract = new web3.eth.Contract(ABI, TROLL_BOX_CONTRACT_ADR_PROD)

        const weiCost = await contract.methods.cost().call()
        const ethCost = weiToEth(weiCost)

        const messageSpanStep = await contract.methods.messageSpanStep().call()

        const queueOpening = await contract.methods.getQueueOpening().call()

        return {
            ethCost,
            messageSpanStep,
            queueOpening,
        }

    }
}

export const createTrollBoxTransaction = async (price, message) => {
    if (hasWeb3()) {

        await enableWeb3()
        const web3 = getWeb3()

        const accounts = await web3.eth.getAccounts()
        const account = accounts[0]

        const contract = new web3.eth.Contract(ABI, TROLL_BOX_CONTRACT_ADR_PROD)

        const options = {
            from: account,
            gas: 500000,
            value: ethToWei(price),
        }
        const transaction = contract.methods.addMessage(message).send(options)
        return resolveOnHash(transaction)

    }
}