import React from "react";
import {numberFormat, round} from "../../util/formatters/numbers";
import {readableSeconds} from "../../util/formatters/timeAndDate";
import {getTrollBoxState, createTrollBoxTransaction, TROLL_BOX_CONTRACT_ADR_PROD} from "../../util/troll-box/troll_box";
import './create-transcation.scss';
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";
import {getMessage} from "../../ducks/trollBox";
import {getCurrency} from "../../ducks/currency";
import Counter from "../util/Counter";
import LoaderHorizontal from "../util/LoaderHorizontal";
import SeeIcon from "mdi-react/ArrowRightCircleIcon";
import {addTransaction} from "../../ducks/liquidityContract";

class CreateTransaction extends React.Component {

    state = {

        minimumPrice: 0.0001,
        messageSpanStep: 60,
        nextOpening: Date.now(),

        price: 0.001,
        sliderValue: 0.001,
        sliderMax: 0.025,
        submitting: false,

    };

    async componentDidMount() {
        try {
            const {
                ethCost,
                messageSpanStep,
                queueOpening,
            } = await getTrollBoxState()

            this.setState({
                cost: ethCost,
                messageSpanStep: messageSpanStep,
                queueOpening: queueOpening * 1000,
            })

        } catch {
        }
    }

    changeNumberInput = e => {
        const {value} = e.target;
        this.setState({
            price: value,
            sliderValue: value,
        });
    };

    changeSlider = e => {
        const {value} = e.target;
        this.setState({
            price: round(e.target.value, 4),
            sliderValue: value,
        })
    };

    getGuarantee() {
        const {price, minimumPrice, messageSpanStep} = this.state;
        return readableSeconds(price / minimumPrice * messageSpanStep);
    }

    submitTransaction = async e => {

        e.stopPropagation && e.stopPropagation()
        e.nativeEvent && e.nativeEvent.stopImmediatePropagation()
        e.preventDefault()

        const {message, addTransaction} = this.props
        const {price} = this.state

        this.setState({
            submitting: true
        })

        try {
            const transactionHash = await createTrollBoxTransaction(price, message)
            addTransaction(transactionHash)
        } catch {
            this.setState({
                submitting: false,
            })
        }
    };


    render() {
        const {price, minimumPrice, sliderMax, sliderValue, submitting} = this.state;
        const {message, currency} = this.props;
        return (
            <div id='create-transaction'>
                <h2>
                    {submitting ? 'Submitting' : 'Submit'} Transaction
                </h2>

                {submitting &&
                <LoaderHorizontal/>
                }

                {!submitting &&
                <form>

                    <p className='message-text'>
                        {message || <span>No message</span>}
                    </p>

                    <div>
                        Your current selection of <b>{numberFormat(price, 4)} ETH</b> (<Counter
                        amount={price * currency.rate}
                        decimals={currency.decimals}
                        prefix={currency.prefix}
                        postfix={currency.postfix}
                    />) guarantees<br/> your
                        message <b>{this.getGuarantee()}</b> exposure.
                    </div>
                    <div className='contract'>
                        <h4>
                            Contract
                        </h4>
                        {TROLL_BOX_CONTRACT_ADR_PROD}
                    </div>
                    <div className='inputs'>

                        <input
                            type="number"
                            step={minimumPrice * 10}
                            value={price}
                            onChange={this.changeNumberInput}
                        />
                        <input
                            type="range"
                            min={minimumPrice}
                            max={sliderMax}
                            step="any"
                            className="slider"
                            id="myRange"
                            value={sliderValue}
                            onChange={this.changeSlider}
                        />
                    </div>

                    <div>
                        <button
                            type="button"
                            onClick={this.submitTransaction}
                        >
                            <SeeIcon/>
                            Submit message to blockchain
                        </button>
                    </div>
                </form>
                }
            </div>
        )
    }

}

const mapStateToProps = state => ({
    message: getMessage(state),
    currency: getCurrency(state),
});

const mapDispatchToProps = {
    addTransaction,
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CreateTransaction));