import React from "react";
import './game-paused-warning.scss'
import AlertOutlineIcon from "mdi-react/AlertOutlineIcon";
import {useSelector} from "react-redux";
import {getIsGamePaused} from "../../ducks/status";

const GamePausedWarning = () => {
    const isGamePaused = useSelector(getIsGamePaused)
    return !!isGamePaused && (
        <div className='game-paused-warning'>
            <AlertOutlineIcon/>
            Game is currently paused!
        </div>
    )
}

export default GamePausedWarning