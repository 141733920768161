import {fetchRateChange} from "../services/ratesAPI";

export const NAME_SPACE = 'rateChange';

const LOAD_RATE_CHANGE_PENDING = `${NAME_SPACE}/LOAD_RATE_CHANGE_PENDING`;
const LOAD_RATE_CHANGE_FAIL= `${NAME_SPACE}/LOAD_RATE_CHANGE_FAIL`;
const LOAD_RATE_CHANGE_SUCCESS = `${NAME_SPACE}/LOAD_RATE_CHANGE_SUCCESS`;

function loadRateChangePending() {
  return {
    type: LOAD_RATE_CHANGE_PENDING
  }
}

function loadRateChangeFail(error) {
  return {
    type: LOAD_RATE_CHANGE_FAIL,
    error: error
  }

}

function loadRateChangeSuccess(response) {
  return {
    type: LOAD_RATE_CHANGE_SUCCESS,
    open: response.open,
    close: response.close,
  }
}

export function loadRateChange() {
  return function (dispatch) {
    dispatch(loadRateChangePending());
    fetchRateChange().then(
      response => dispatch(loadRateChangeSuccess(response)),
      error => dispatch(loadRateChangeFail(error)),
    )
  }
}


const initialState = {
  isLoading: false
  , isDataLoaded: false
  , error: null
  , open: 0
  , close: 0
};

function rateChangeReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_RATE_CHANGE_PENDING:
      return {
        ...state
        , isLoading: true
      };
    case LOAD_RATE_CHANGE_FAIL:
      return {
        ...state
        , isLoading: false
        , isDataLoaded: true
        , error: action.error
      };
    case LOAD_RATE_CHANGE_SUCCESS:
      return {
        ...state
        , isLoading: false
        , isDataLoaded: true
        , open: action.open
        , close: action.close
      };
    default:
      return state;
  }
}

function getState(state) {
  return state[NAME_SPACE];
}

export function getDiff(state){
  return getState(state).close - getState(state).open;
}

export function getDiffPercent(state) {
  const open = getState(state).open;
  const close = getState(state).close;
  const diff = close - open;
  return open === 0 ? 0 : diff / open;
}


export default rateChangeReducer;