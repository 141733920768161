import React from 'react';
import {getActiveNotification} from "../../ducks/notifications";
import {connect} from "react-redux";
import './notification.scss';

const Notification = ({activeNotification}) => (
  <div id='notification'>
    {activeNotification.text}
  </div>
);

const mapStateToProps = state =>{
  return {
    activeNotification: getActiveNotification(state),
  }
};



export default connect(mapStateToProps)(Notification);