import Quarters from "./quarters";

export default class Estimate {

  static fromCurrentQuarter(currentQuarterProfits) {

    const quarters = Quarters.getAll();

    const start = quarters[quarters.length - 1].start;
    const now = new Date().getTime() / 1000;
    const startOfPeriod = quarters[0].start;
    const endOfPeriod = quarters[0].end;

    const remainingPeriod = endOfPeriod - now;

    const maxPeriodWeight = .90;
    const percentageOfPeriodDone = remainingPeriod / (endOfPeriod - startOfPeriod);
    const thisQuarterWeight = maxPeriodWeight * (1 - percentageOfPeriodDone);

    let totalProfit = currentQuarterProfits;
    quarters.forEach(q => totalProfit += q.profits);

    const growthPerSecondTotal = totalProfit / (now - start);
    const growthPerSecondQuarter = currentQuarterProfits < 0 ? 0 : currentQuarterProfits / (now - startOfPeriod);

    const growthPerSecondWeighted = (growthPerSecondTotal * (1 - thisQuarterWeight)) + (growthPerSecondQuarter * thisQuarterWeight);

    const estimateQuarter = (growthPerSecondWeighted * remainingPeriod) + currentQuarterProfits;
    const estimateYearly = growthPerSecondWeighted * (60 * 60 * 24 * 365);

    return {
      estimateQuarter,
      estimateYearly,
      growthPerSecondTotal,
      growthPerSecondQuarter,
      growthPerSecondWeighted,
    }

  }

}