import {fetchStats} from "../services/statisticsAPI";
import {getFromLocalStorage, saveToLocalStorage} from "../util/persistance";
import oldStats from '../components/tab-stats/oldStats.json'

export const NAME_SPACE = 'statistics';

const LOAD_STATS_PENDING = `${NAME_SPACE}/LOAD_STATS_PENDING`;
const LOAD_STATS_FAIL = `${NAME_SPACE}/LOAD_STATS_FAIL`;
const LOAD_STATS_SUCCESS = `${NAME_SPACE}/LOAD_STATS_SUCCESS`;

const SET_OPTION = `${NAME_SPACE}/SET_OPTION`;
const SET_DATE_FROM = `${NAME_SPACE}/SET_DATE_FROM`;
const SET_DATE_TO = `${NAME_SPACE}/SET_DATE_TO`;

function loadStatsPending(){
  return {
    type: LOAD_STATS_PENDING
  }
}

function loadStatsFail(error){
  return {
    type: LOAD_STATS_FAIL,
    error: error
  }

}

function loadStatsSuccess(response){
  // if(!response.data.balance){
  //   return loadStatsFail('WS error...')
  // }
  return {
    type: LOAD_STATS_SUCCESS,
    dates: response.data.dates,
    last24hours: response.data.last24hours,
  }
}

export function setOption(optionKey, value) {
  return {
    type: SET_OPTION,
    optionKey: optionKey,
    value: value,
  }
}

export function setDateFrom(value) {
  return {
    type: SET_DATE_FROM,
    value: value,
  }
}

export function setDateTo(value) {
  return {
    type: SET_DATE_TO,
    value: value,
  }
}

export function loadStats(){
  return function (dispatch){
    dispatch(loadStatsPending());
    fetchStats().then(
      response => dispatch(loadStatsSuccess(response)),
      error => dispatch(loadStatsFail(error)),
    )
  }
}

export const GRAPH_OPTIONS = {
  showPlayerCount: getFromLocalStorage(`${NAME_SPACE}/showPlayerCount`) || false ,
  showNewPlayerCount: getFromLocalStorage(`${NAME_SPACE}/showNewPlayerCount`) || false,
  showBetCount: getFromLocalStorage(`${NAME_SPACE}/showBetCount`) || false,
  showHouseProfitChange: getFromLocalStorage(`${NAME_SPACE}/showHouseProfitChange`) || false,
  showHouseProfitEV: getFromLocalStorage(`${NAME_SPACE}/showHouseProfitEV`) || true,
  showHouseProfitTotal: getFromLocalStorage(`${NAME_SPACE}/showHouseProfitTotal`) || true,
  showEthWagered: getFromLocalStorage(`${NAME_SPACE}/showEthWagered`) || false,
  showAvgBetSize: getFromLocalStorage(`${NAME_SPACE}/showAvgBetSize`) || false,
  showMovingAverages: getFromLocalStorage(`${NAME_SPACE}/showMovingAverages`) || true,
  showPeriodMarkers: getFromLocalStorage(`${NAME_SPACE}/showPeriodMarkers`) || true,
};


const initialState = {
  isLoading: false,
  isDataLoaded: false,
  error: null,
  dates: [],
  last24hours: {},
  options: GRAPH_OPTIONS,
  dateFrom: 365,
  dateTo: 0,
};

function statisticsReducer(state = initialState, action){
  switch(action.type){
    case LOAD_STATS_PENDING:
      return {
        ...state
        , isLoading: true
      };
    case LOAD_STATS_FAIL:
      return {
        ...state,
        isLoading: false,
        isDataLoaded: true,
        error: action.error,
      };
    case LOAD_STATS_SUCCESS:
      const totalLengthNew = oldStats.length + action.dates.length - 1;
      const totalLengthold = oldStats.length + state.dates.length - 1;
      return {
        ...state,
        isDataLoaded: true,
        dates: [...action.dates],
        last24hours: {...action.last24hours},
        dateFrom: state.dateFrom === totalLengthold ? totalLengthNew : state.dateFrom,
      };
    case SET_OPTION:
      saveToLocalStorage(`${NAME_SPACE}/${action.optionKey}`, action.value);
      return {
        ...state,
        options: {
          ...state.options,
          [action.optionKey]: action.value,
        }
      };
    case SET_DATE_FROM:
      return {
        ...state
        , dateFrom: action.value
      };
    case SET_DATE_TO:
      return {
        ...state
        , dateTo: action.value
      };
    default:
      return state;
  }
}

function getState(state){
  return state[NAME_SPACE];
}

export function getHistory(state){
  return getState(state).dates;
}

export function get24HourActivity(state){
  return getState(state).last24hours;
}

export function isDataLoaded(state){
  return getState(state).isDataLoaded && getHistory(state).length > 0;
}

export function getOptions(state) {
  return getState(state).options
}

export function getDateFrom(state){
  return getState(state).dateFrom;
}

export function getDateTo(state){
  return getState(state).dateTo;
}

export function getTotalHistoryLength(state){
  return getHistory(state).length + oldStats.length;
}

export default statisticsReducer;