import React from 'react';
import AddressFormat from "../../util/formatters/address";
import {Link} from "react-router-dom";
import Counter from "../util/Counter";

import './player.scss';
import NumberUpIcon from "mdi-react/ArrowUpIcon";
import NumberDownIcon from "mdi-react/ArrowDownIcon";
import WageredIcon from "mdi-react/EthereumIcon";
import BetsIcon from "mdi-react/DiceMultipleIcon";
import {ToolTip} from "../util/ToolTip";
import {TOP_PLAYERS_METRICS} from "../../ducks/topPlayers";

const Player = ({player, index, setTopPlayersMetric, metric, period}) => {
    const {user, rolls, wagered, sum} = player;
    if(metric === 'won' && sum <= 0){
        return null;
    }
    return(
        <div className='player'>
            <div
                className='player__moniker'
            >
                <span className={'player__moniker__placement'}>
                    {index + 1}
                </span>
                <ToolTip
                    text='Live rolls for this player'
                >
                    <Link to={`/rolls/${player.user}`}>
                        {AddressFormat.parseHashAddress(user)}
                    </Link>
                </ToolTip>
            </div>
            <div className='player__numbers'>
                <Profit
                    profit={sum}
                    setTopPlayersMetric={setTopPlayersMetric}
                    first={metric === TOP_PLAYERS_METRICS.PROFIT}
                    period={period}
                />
                <Wagered
                    wagered={wagered}
                    setTopPlayersMetric={setTopPlayersMetric}
                    first={metric === TOP_PLAYERS_METRICS.WAGERED}
                    period={period}
                />
                <Rolls
                    rolls={rolls}
                    setTopPlayersMetric={setTopPlayersMetric}
                    first={metric === TOP_PLAYERS_METRICS.ROLLS}
                    period={period}
                />
            </div>
        </div>
    )
};


const Profit = ({profit, first, period}) => (
    <Link
        className={`player__profit ${first && 'first'}`}
        to={`/top-players/${TOP_PLAYERS_METRICS.PROFIT}/${period}`}
    >
        {profit >= 0 ?
            <NumberUpIcon className={`icon_positive`}/> :
            <NumberDownIcon className={`icon_negative`}/>
        }
        <Counter
            amount={profit}
            decimals={2}
            postfix={' ETH'}
            absolute={true}
        />
        {profit >= 0 ? `profit` : `loss`}
    </Link>
);

const Wagered = ({wagered, first, period}) => (
    <Link
        className={`player__wagered ${first && 'first'}`}
        to={`/top-players/${TOP_PLAYERS_METRICS.WAGERED}/${period}`}
    >
        <WageredIcon />
        <Counter
            amount={wagered}
            smartDecimals={true}
        />
        wagered
    </Link>
);

const Rolls = ({rolls, first, period}) => (
    <Link
        className={`player__rolls ${first && 'first'}`}
        to={`/top-players/${TOP_PLAYERS_METRICS.ROLLS}/${period}`}
    >
        <BetsIcon />
        <Counter
            amount={rolls}
            decimals={0}
        />
        roll{rolls > 1 && 's'}
    </Link>
);

export default Player;