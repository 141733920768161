import React from 'react';
import {numberFormat} from "../../../util/formatters/numbers";
import AddressFormat from "../../../util/formatters/address";
import {LIQUIDITY_CONTRACT_ADDRESS} from "../../../util/liquidity-contract/liquidity_contract";
import {Link} from "react-router-dom";
import TimeAgo from "../../tab-rolls/TimeAgo";
import NewTab from "mdi-react/OpenInNewIcon";

const Trade = ({trade}) => {
  const isBuy = trade.seller === LIQUIDITY_CONTRACT_ADDRESS;
  const user = isBuy ? trade.buyer : trade.seller;
  const userNumerical = AddressFormat.getNumerical(user, 3);
  if(trade.eth === 0 || trade.dice === 0)return null;
  return (
    <tr className="history-trade">
      <td className={`user-${userNumerical}`}>
        <Link to={`/rolls/${user}`}>
          {AddressFormat.parseHashAddress(user)}
        </Link>
      </td>
      <td className={`user-${userNumerical}`}>
        <Link to={`/rolls/${user}`}>
          {AddressFormat.parseHashAddress(user, true)}
        </Link>
      </td>
      <td className={isBuy ? 'buy' : 'sale'}>
        {isBuy ? 'BUY' : 'SELL'}
      </td>
      <td>
        <TimeAgo timestamp={trade.timeStamp * 1000} />
      </td>
      <td className="number">
        {numberFormat(trade.dice,-1)}
      </td>
      <td className="number">
        {numberFormat(trade.eth,4)}
      </td>
      <td className={`number ${isBuy ? 'buy' : 'sale'}`}>
        {numberFormat(trade.eth/trade.dice,6)}
      </td>
      <td className={`number`}>
        <a
          href={`https://etherscan.io/tx/${trade.hash}`}
          target="_blank"
          rel="noopener noreferrer"
          title="View transaction on Etherscan.io"
        >
          <NewTab />
        </a>
      </td>
    </tr>
  )
};

export default Trade;