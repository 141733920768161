import React from "react";
import './dividend-countdown.scss'
import {useSelector} from "react-redux";
import {
    getIsDividendPayoutActive,
    getIsFreezeWithinTheDay,
    getIsFreezeWithinTheWeek, getIsThawWithinTheDay,
    getNextFreeze,
    getNextThaw
} from "../../ducks/status";
import {useTime} from "../../hooks/countdown";
import {getTimeDiff} from "../../util/formatters/timeAndDate";

const DividendCountdown = () => {

    const nextFreeze = useSelector(getNextFreeze)
    const nextThaw = useSelector(getNextThaw)
    const isDividendPayoutActive = useSelector(getIsDividendPayoutActive)
    const isFreezeWithinTheWeek = useSelector(getIsFreezeWithinTheWeek)

    return (isDividendPayoutActive || isFreezeWithinTheWeek) && (
        <div className='dividend-countdown'>
            <Countdown
                end={isDividendPayoutActive ? nextThaw * 1000 : nextFreeze * 1000}
                header={isDividendPayoutActive ? 'Time left of Wealth Week' : 'Wealth Week starts in'}
            />
        </div>
    )
}

export const Countdown = ({end, header}) => {
    const isFreezeWithinTheDay = useSelector(getIsFreezeWithinTheDay)
    const isThawWithinTheDay = useSelector(getIsThawWithinTheDay)
    const now = useTime(200)
    const {
        diffPartDays,
        diffPartHours,
        diffPartMinutes,
        diffPartSeconds,
    } = getTimeDiff(now, end)
    return (
        <div>
            <h6>
                {header}
            </h6>
            <DatePart
                value={diffPartDays}
                postfix={'d'}
                isGrayable={true}
                isGolden={isFreezeWithinTheDay}
                isRed={isThawWithinTheDay}
            />
            <DatePart
                value={diffPartHours}
                postfix={'h'}
                isGrayable={diffPartDays === 0}
                isGolden={isFreezeWithinTheDay}
                isRed={isThawWithinTheDay}
            />
            <DatePart
                value={diffPartMinutes}
                postfix={'m'}
                isGrayable={diffPartDays === 0 && diffPartHours === 0}
                isGolden={isFreezeWithinTheDay}
                isRed={isThawWithinTheDay}
            />
            <DatePart
                value={diffPartSeconds}
                postfix={'s'}
                isGrayable={false}
                isGolden={isFreezeWithinTheDay}
                isRed={isThawWithinTheDay}
            />
        </div>
    )
}

const DatePart = ({value, postfix, isGolden = false, isRed = true, isGrayable = false}) => {
    const isGray = value === 0 && isGrayable;
    return (
        <div
            className={`date-part ${isGolden && !isGray && 'golden'} ${isRed && !isGray && 'red'} ${isGray && 'dark'}`}>
            <span className={'date-part__value'}>{value}</span>
            <span className={'date-part__postfix'}>{postfix}</span>
        </div>
    )
}

export default DividendCountdown