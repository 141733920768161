import React from 'react';
import {Link} from "react-router-dom";
import {numberFormat} from "../../util/formatters/numbers";

export const NotificationWealthWeekComingUp = ({wealthWeekStart, wealthWeekEnd, romanNumeral, n, nth, pot}) => {
    if(pot <= 0){
        return (
            <>
                <h3>
                    Wealth Week {romanNumeral} coming up
                </h3>
                <p>
                    Starting <b>{new Date(wealthWeekStart * 1000).toLocaleDateString()} - {new Date(wealthWeekStart * 1000).toLocaleTimeString()}</b> (your
                    local time), the {nth} reward period will start and ÐICE tokens will be locked to their accounts/
                    exchanges and become non-transferable for a week.
                </p>
                <p>
                    Since this quarter ended with a loss, no dividends will be available.
                </p>
                <p>
                    Tokens will be unlocked and transferable
                    at <strong>{new Date(wealthWeekEnd * 1000).toLocaleDateString()} - {new Date(wealthWeekEnd * 1000).toLocaleTimeString()}</strong>
                </p>
            </>
        )
    }
    return (
        <>
            <h3>
                Wealth Week {romanNumeral} coming up
            </h3>
            <p>
                Starting <b>{new Date(wealthWeekStart * 1000).toLocaleDateString()} - {new Date(wealthWeekStart * 1000).toLocaleTimeString()}</b> (your
                local time), the {nth} reward period will start and ÐICE tokens will be locked to their accounts/
                exchanges and become non-transferable for a week.
            </p>
            <p>
                During this time, ÐICE holders will be able to claim their share of the profits, but only if their
                tokens are stored in a wallet they control. Remember to pull your tokens off exchanges!
            </p>
            <p>
                <b>{numberFormat(pot)} ETH</b> has been moved out of the game contract in preparation for dividend distribution.
            </p>
            <p>
                You can take a look at the <Link to="dividends">dividend history page</Link> to see your share for
                quarter {n}.
            </p>
            <p>
                Tokens will be unlocked and transferable
                at <strong>{new Date(wealthWeekEnd * 1000).toLocaleDateString()} - {new Date(wealthWeekEnd * 1000).toLocaleTimeString()}</strong>
            </p>
            <p>
                More info here: <a href="https://rewards.etheroll.com/#tab4" target="_blank"
                                   rel="noopener noreferrer">rewards.etheroll.com</a>
            </p>
        </>
    )
};

export const NotificationWealthWeekHasBegun = ({wealthWeekEnd, romanNumeral, nth, pot}) => {
    if(pot <= 0){
        return (
            <>
                <h3>
                    Wealth Week {romanNumeral} has begun
                </h3>
                <p>
                    No dividends will be distributed since last quarter did not end in profit.
                </p>
                <p>
                    Wealth Week {romanNumeral} closes <strong>{new Date(wealthWeekEnd * 1000).toLocaleDateString()} - {new Date(wealthWeekEnd * 1000).toLocaleTimeString()}</strong> (your local time).
                </p>
            </>
        )
    }
    return (
        <>
            <h3>
                Wealth Week {romanNumeral} has begun
            </h3>
            <p>
                Rewards for the {nth} dividend period are now available!
            </p>
            <p>
                Wealth Week {romanNumeral} closes <strong>{new Date(wealthWeekEnd * 1000).toLocaleDateString()} - {new Date(wealthWeekEnd * 1000).toLocaleTimeString()}</strong> (your local time). Remember to claim your share by then!
            </p>
            <p>
                See <a href="https://rewards.etheroll.com/#tab4" target="_blank"
                       rel="noopener noreferrer">rewards.etheroll.com</a> for instructions.
            </p>
        </>
    )
};

export const NotificationWealthWeekIsEnded = ({romanNumeral, amountUnclaimed = 0}) => (
    <>
        <h3>
            Wealth Week {romanNumeral} is ended
        </h3>
        <p>
            ÐICE tokens have been unlocked and are once again transferable.
        </p>
        {amountUnclaimed > 0 &&
        <p>
            {numberFormat(amountUnclaimed, 2)} ETH went unclaimed.
        </p>
        }
    </>
);

export const LiquidityContractNowOpen = () => (
    <>
        <h3>
            Liquidity Contract Open
        </h3>
        <p>
            Do you have some recent dividends you wish to spend, or some DICE tokens you want to get rid of now
            that the freeze is over?
        </p>
        <p>
            The <Link to='liquidity-contract'>DICE Token Liquidity Contract</Link> is once again open for business!
        </p>
    </>
);