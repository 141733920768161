import React from 'react';
import './hour-stats.scss';
import {lastHourStats} from "../../ducks/rolls";
import connect from "react-redux/es/connect/connect";
import Counter from "../util/Counter";
import AddressFormat from "../../util/formatters/address";
import PlayerIcon from "mdi-react/AccountIcon";
import {Link} from "react-router-dom";

const HourStats = ({lastHourStats, closePopup = () => {}}) => {
  const {
    highestWager,
    highestWagerUser,
    highestWin,
    highestWinUser,
    highestLoss,
    highestLossUser,
    mostWagers,
    mostWagersUser,
  } = lastHourStats;
  return (
    <div id='last-hour'>

      <h2>
        Last hour statistics
      </h2>

      <div className='data-points'>

        <DataPoint
          header={`Highest win`}
          closePopup={closePopup}
          user={highestWinUser}
        >
          <Counter
            amount={highestWin}
            absolute={true}
            decimals={2}
            postfix={' ETH'}
          />
        </DataPoint>

        <DataPoint
          header={`Highest loss`}
          closePopup={closePopup}
          user={highestLossUser}
        >
          <Counter
            amount={highestLoss}
            absolute={true}
            decimals={2}
            postfix={' ETH'}
          />
        </DataPoint>

        <DataPoint
          header={`Highest wager`}
          closePopup={closePopup}
          user={highestWagerUser}
        >
          <Counter
            amount={highestWager}
            absolute={true}
            decimals={2}
            postfix={' ETH'}
          />
        </DataPoint>

        <DataPoint
          header={`Most wagers`}
          closePopup={closePopup}
          user={mostWagersUser}
        >
          <Counter
            amount={mostWagers}
            absolute={true}
            decimals={0}
            postfix={' rolls'}
          />
        </DataPoint>

      </div>

    </div>
  )
};

const DataPoint = ({user, closePopup, header, children}) => (
  <div className='data-points__data-point'>
    <h4>
      {header}
    </h4>
    <div className='data-points__data-point__value'>
      {children}
      <span className='user'>
          <Link
            to={`/rolls/${user}`}
            onClick={closePopup}
          >
              <PlayerIcon/>
            {AddressFormat.parseHashAddress(user)}
          </Link>
      </span>
    </div>
  </div>

);

const mapStateToProps = state => {
  return {
    lastHourStats: lastHourStats(state),
  }
};
export default connect(mapStateToProps)(HourStats);