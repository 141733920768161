import React from 'react';
import {getHouseProfitTotal} from "../../ducks/status";
import {getCurrency} from "../../ducks/currency";
import connect from "react-redux/es/connect/connect";
import Quarters from "../../data/quarters";
import Quarter from "./Quarter";
import Counter from "../util/Counter";

import './tab-dividends.scss';

const TabDividends = ({houseProfitTotal, currency}) => (
  <div id='tab-dividends'>
    <h1>
      Dividend history
    </h1>
    {Quarters.getAll().map((quarter, idx) =>
      <Quarter
        key={idx}
        idx={idx}
        start={quarter.start}
        end={quarter.end}
        profits={quarter.profits}
      />
    )}
    <div className='totals'>
      <h4>
        Total house profit through all quarters
      </h4>
      <div className='data-points'>
        <div className='data-points__data-point'>
          <Counter
            amount={houseProfitTotal}
            decimals={2}
            postfix=" ETH"
          />
        </div>
        <div className='data-points__data-point__sub'>
          <Counter
            amount={houseProfitTotal * currency.rate}
            decimals={currency.decimals}
            prefix={currency.prefix}
            postfix={currency.postfix}
          />
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = state => {
  return {
    houseProfitTotal: getHouseProfitTotal(state),
    currency: getCurrency(state),
  }
};

export default connect(mapStateToProps)(TabDividends);