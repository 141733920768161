import React from 'react';
import {connect} from "react-redux";
import {closePopup, getPopupContent, isPopupActive} from "../../ducks/popup";

import './popup.scss';
import ClickOutsideCatcher from "../util/ClickOutsideCatcher";
import CloseIcon from "mdi-react/CloseIcon";

class Popup extends React.Component {
  render() {
    const {content, isActive, closePopup} = this.props;
    return (
        <div id='popup' className={`${!isActive ? 'inactive' : 'active'} ${!content && 'no-content'}`}>
          <div
              id='popup__modal'
              className='no-select'
              onClick={closePopup}
          />
            <div id='popup__content'>
                <Wrapper isActive={isActive} closePopup={closePopup}>
              <button
                onClick={closePopup}
                id='popup__content__close'
              >
                <CloseIcon />
              </button>
              {content ? React.cloneElement(content, { closePopup: closePopup} ) : ''}
                </Wrapper>
            </div>
        </div>
    )
  }
}

const Wrapper = ({isActive, closePopup, children}) => {
  return isActive ? <ClickOutsideCatcher onCatch={closePopup} children={children} /> : children;
};


const mapStateToProps = state => {
  return {
    content: getPopupContent(state),
    isActive: isPopupActive(state),
  }
};

const mapDispatchToProps = {
  closePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Popup);