import React from "react";

class KeyDownCatcher extends React.Component {

  constructor(props) {
    super(props);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleKeyPress(event) {
    const {onEnter, onTab, onShiftTab, onEsc, onSpace} = this.props;

    if (onEnter && isEnterPressed(event)) {
      onEnter();
      return;
    }

    if (onShiftTab && isShiftTabPressed(event)) {
      onShiftTab();
      return;
    }

    if (onTab && isTabPressed(event)) {
      onTab();
      return;
    }

    if (onEsc && isEscPressed(event)) {
      onEsc();
      return;
    }

    if (onSpace && isSpacePressed(event)) {
      onSpace();
      return;
    }

  };

  render() {
    const {children, className='', onClick} = this.props;
    return (
      <span
        onKeyDown={this.handleKeyPress}
        onClick={onClick}
        className={className}
      >
        {children}
      </span>
    )
  }
}

export const KEY_ENTER = 'Enter';
export const KEY_KODE_ESC = 27;
export const KEY_KODE_SPACE_BAR = 32;
export const KEY_TAB = 'Tab';

export function isEnterPressed(event) {
  return (event.key === KEY_ENTER);
}

export function isEscPressed(event) {
  const keyCode = event.which || event.keyCode;
  return (keyCode === KEY_KODE_ESC);
}

export function isTabPressed(event) {
  return (!event.shiftKey && event.key === KEY_TAB);
}

export function isShiftTabPressed(event) {
  return (event.shiftKey && event.key === KEY_TAB);
}

export function isSpacePressed(event) {
  const keyCode = event.which || event.keyCode;
  return (keyCode === KEY_KODE_SPACE_BAR);
}

export default KeyDownCatcher;