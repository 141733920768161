import React from 'react';
import Card from "./Card";
import {ToolTip} from "../util/ToolTip";
import EthereumIcon from "mdi-react/EthereumIcon";
import RatesIcon from "mdi-react/TagIcon";
import DiceIcon from "mdi-react/CoinsIcon";
import connect from "react-redux/es/connect/connect";
import Counter from "../util/Counter";
import {getDiffPercent} from "../../ducks/rateChange";
import NumberDownIcon from "mdi-react/ArrowDownIcon";
import NumberUpIcon from "mdi-react/ArrowUpIcon";
import {getCurrency} from "../../ducks/currency";
import {getUniswap10xBuyRate} from "../../ducks/status";

const CardRates = ({currency, diceRate, diceRateChangePercent}) => {
  return (
    <Card className='rates'>
      <div className='card__icon-background'>
        <RatesIcon rotate={10}/>
      </div>
      <h4>
        <RatesIcon rotate={10}/>
        Rates
      </h4>
      <div className='rates__data-points'>
        <div className='rates__data-points__data-point'>
          <ToolTip text={`ETH rate`} setCenter={true}>
            <div className='rates__data-points__data-point__main-value'>
              <EthereumIcon/>
              <Counter
                amount={currency.rate}
                decimals={currency.decimals}
                prefix={currency.prefix}
                postfix={currency.postfix}
              />
            </div>
          </ToolTip>
        </div>
        <div className='rates__data-points__data-point'>
          <ToolTip text={`ÐICE/ ETH rate`} setCenter={true}>
            <div className='rates__data-points__data-point__main-value'>
              <DiceIcon/>
              <Counter
                amount={diceRate}
                decimals={6}
              />
            </div>
            <div className='rates__data-points__data-point__sub-value'>
              {diceRateChangePercent < 0 ?
                <NumberDownIcon className='icon_negative'/> :
                <NumberUpIcon className='icon_positive'/>
              }
              <Counter
                amount={diceRateChangePercent * 100}
                decimals={2}
                absolute={true}
                postfix='%'
              />
            </div>
          </ToolTip>
        </div>
      </div>
    </Card>
  )
};

const mapStateToProps = state => {
  return {
    currency: getCurrency(state),
    diceRate: getUniswap10xBuyRate(state),
    diceRateChangePercent: getDiffPercent(state),
  }
};

export default connect(mapStateToProps)(CardRates);