import React from "react";
import {getOptions, setOption} from "../../ducks/stats";
import connect from "react-redux/es/connect/connect";

import PlayerIcon from "mdi-react/AccountIcon";
import NewPlayerIcon from "mdi-react/AccountPlusIcon";
import BetsIcon from "mdi-react/DiceMultipleIcon";
import WageredIcon from "mdi-react/EthereumIcon";
import HouseProfitTotalIcon from "mdi-react/BankIcon";
import HouseProfitEVIcon from "mdi-react/BankTransferIcon";
import HouseProfitChangeIcon from "mdi-react/BankTransferInIcon";
import BetSizeIcon from "mdi-react/Dice3Icon";
import AvgIcon from "mdi-react/ScaleBalanceIcon";
import QuarterIcon from "mdi-react/CalendarStarIcon";

import './options.scss';

export const GRAPH_OPTIONS_DETAILS = {
  showBetCount: {
    name: 'Roll Count',
    Icon: BetsIcon,
  },
  showPlayerCount: {
    name: 'Player Count',
    Icon: PlayerIcon,
  },
  showNewPlayerCount: {
    name: 'New Player Count',
    Icon: NewPlayerIcon,
  },
  showEthWagered: {
    name: 'ETH Wagered',
    Icon: WageredIcon,
  },
  showAvgBetSize: {
    name: 'Avg. Bet Size',
    Icon: BetSizeIcon,
  },
  showHouseProfitChange: {
    name: 'House Profit Change',
    Icon: HouseProfitChangeIcon,
  },
  showHouseProfitEV: {
    name: 'House Profit EV',
    Icon: HouseProfitEVIcon,
  },
  showHouseProfitTotal: {
    name: 'House Profit Total',
    Icon: HouseProfitTotalIcon,
  },
  showMovingAverages: {
    name: 'Moving Averages',
    Icon: AvgIcon,
  },
  showPeriodMarkers: {
    name: 'Quarter Markers',
    Icon: QuarterIcon,
  },
};


export class Options extends React.Component {

  toggleOption = key => {
    const {options, setOption} = this.props;
    setOption(key, !options[key]);
  };

  render() {
    const {options} = this.props;
    return (
      <div id="stats-options">
        <div className={`options`}>
          {Object.keys(GRAPH_OPTIONS_DETAILS).map(optionKey => {
            const {name, Icon} = GRAPH_OPTIONS_DETAILS[optionKey];
            const isChecked = options[optionKey];
            return (
              <div
                key={optionKey}
                onClick={() => this.toggleOption(optionKey)}
                className={`options__toggler no-select ${isChecked && 'checked'}`}
              >
                {Icon &&
                <span className='options__toggler__icon'>
                  <Icon/>
                </span>
                }
                <span className='options__toggler__text'>
                  {name}
                </span>
              </div>
            )
          })}
        </div>
        <div className='mobile-help'>
          If you wonder what the different buttons represent, try flipping your phone!
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    options: getOptions(state),
  }
};

const mapDispatchToProps = {
  setOption,
};

export default connect(mapStateToProps, mapDispatchToProps)(Options);