export const CHART_COLORS = [

  // bets
  "rgb(230, 126, 34)",
  "rgba(230, 126, 34, .5)",

  // players
  "rgb(52, 152, 219)",
  "rgba(52, 152, 219, .5)",

  // house profit change 4
  "rgb(39, 174, 96)",
  "rgba(39, 174, 96,.5)",

  // eth wagered 6
  "rgb(241, 196, 15)",
  "rgba(241, 196, 15,.5)",

  // avg bet size 8
  "rgb(231, 76, 60)",
  "rgba(231, 76, 60, .5)",

  "rgb(241, 196, 15)",
  "rgba(241, 196, 15, .5)",

  // house profit total 12
  "rgb(155, 89, 182)",
  "rgba(155, 89, 182, .5)",

  // house profit ev 14
  "rgb(26, 188, 156)",
  "rgba(26, 188, 156, .5)",

  // new players 16
  "rgb(22, 160, 133)",
  "rgba(22, 160, 133, .5)",

  // period markers 18
  "rgba(255, 255, 255, .5)",
  "rgba(255, 255, 255, .5)",

];