import React from 'react';
import Counter from "../util/Counter";
import {getPlayerStats,} from "../../ducks/rolls";

import './player-stats.scss';
import NumberUpIcon from "mdi-react/ArrowUpIcon";
import NumberDownIcon from "mdi-react/ArrowDownIcon";
import {useSelector} from "react-redux";

const PlayerStats = ({player}) => {
  const playerStats = useSelector(getPlayerStats);
  if (!playerStats) return null;
  const {sum, wagered, rolls, ticker_messages: messages, first_bet: firstBet} = playerStats;
  return (
    <div id='player-stats'>

      <div className='data-points'>

        <div className='data-points__data-point'>
          <h4>
            Total profit
          </h4>
          <span>
            {sum < 0 ?
              <NumberDownIcon className='icon_loss'/> :
              <NumberUpIcon className='icon_win'/>
            }
            <Counter
              amount={sum}
              smartDecimals={true}
              absolute={true}
            /> ETH
          </span>
        </div>

        <div className='data-points__data-point'>
          <h4>
            Wagered
          </h4>
          <span>
            <Counter
              amount={wagered}
              smartDecimals={true}
            /> ETH
          </span>
        </div>

        <div className='data-points__data-point'>
          <h4>
            Rolls
          </h4>
          <span>
            <Counter
              amount={rolls}
              decimals={0}
            />
          </span>
        </div>

        <div className='data-points__data-point'>
          <h4>
            Average Wager
          </h4>
          <span>
            <Counter
              amount={wagered / rolls}
              decimals={2}
            />
          </span>
        </div>

        <div className='data-points__data-point'>
          <h4>
            Messages
          </h4>
          <span>
            <Counter
              amount={messages}
              decimals={0}
            />
          </span>
        </div>

        <div className='data-points__data-point'>
          <h4>
            First Roll
          </h4>
          <span>
            {firstBet ? new Date(firstBet * 1000).toLocaleDateString() : `-`}
          </span>
        </div>

      </div>

      <div className='etherscan-link'>
        <a href={`https://etherscan.io/address/${player}`} target="_blank" rel="noopener noreferrer" >&raquo; View address on etherscan</a>
      </div>

    </div>
  )
};

export default PlayerStats;