import axios from "axios/index";

export function fetchStatus(){

  const host = process.env.REACT_APP_API_HOST;
  const path = '/balance';

  return axios.get(host + path)
    .then(response => {
      return response
    })
    .catch(error => {
      throw new Error(error)
    })

}