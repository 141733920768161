import React from 'react';

import './tool-tip.scss';

export const ToolTip = ({text, children, setRight = false, setCenter = false, className = '', onClick}) => {
  return (
    <div className={`tool-tip ${className}`} onClick={onClick}>
      {children}
      {text &&
      <div className={`tool-tip__text ${setRight && `set-right`} ${setCenter && `set-center`}`}>
        {text}
      </div>
      }
    </div>
  )
};