import React from 'react';
import connect from "react-redux/es/connect/connect";
import {getRollFromId} from "../../ducks/rolls";
import TimeAgo from "./TimeAgo";

import './roll-details.scss';
import {Link} from "react-router-dom";
import AddressFormat from "../../util/formatters/address";
import {numberFormat} from "../../util/formatters/numbers";
import {readableSeconds} from "../../util/formatters/timeAndDate";
import LoaderCircle from "../util/LoaderCircle";
import {getCurrency} from "../../ducks/currency";
import Counter from "../util/Counter";

const resultText = roll => {
  if (roll.status === 0) {
    const operator = roll.limit === roll.result ? '=' : '›';
    return (<span className="result-text"> {operator} {roll.limit}</span>)
  }
  if (roll.status === 1) {
    return (<span className="result-text"> ‹ {roll.limit}</span>)
  }
};

const statColor = roll => {
  if (roll.status === 0) return 'loss';
  if (roll.status === 1) return 'win';
  return ''
};

const RollDetails = ({getRollFromId, rollId, closePopup, currency}) => {
  const roll = getRollFromId(rollId);
  if(!roll)return null;
  const {user, timestamp, r_timestamp, hash, limit, stake, payout, status, result} = roll;
  const date = new Date(timestamp * 1000);
  const reward = payout - stake;
  return (
    <div className='roll-details'>
      <div className='roll-details__time'>
        <div className='roll-details__time__full'>
          {new Date(date).toLocaleString()}
        </div>
        <div className='roll-details__time__relative'>
          <TimeAgo timestamp={timestamp * 1000} /> by <Link to={`/rolls/${user}`} onClick={closePopup}>{AddressFormat.parseHashAddress(user)}</Link>
        </div>
      </div>
      <div className='roll-details__stake'>
        <div className='data-header'>
          Player bet
        </div>
        <div className='data-values'>
          <b>{numberFormat(stake, 2)}</b> ETH (<Counter
                                                  amount={stake * currency.rate}
                                                  decimals={currency.decimals}
                                                  prefix={currency.prefix}
                                                  postfix={currency.postfix}
                                               />)
        </div>
      </div>
      <div className='roll-details__limit'>
        <div className='data-header'>
          Trying to roll
        </div>
        <div className='data-values'>
          less than <b>{limit}</b> — {100 * (limit - 1) / 100}% chance
        </div>
      </div>
      <div className='roll-details__reward'>
        <div className='data-header'>
          For a reward of
        </div>
        <div className='data-values'>
          <b>{numberFormat(reward, 2)} </b> ETH (<Counter
                                                  amount={reward * currency.rate}
                                                  decimals={currency.decimals}
                                                  prefix={currency.prefix}
                                                  postfix={currency.postfix}
                                                />)
          — {numberFormat((Math.round((payout - stake) / stake * 100) / 100) + 1, 2)} x payout
        </div>
      </div>
      {status === null ?
        <div className='roll-details__text'>
          <LoaderCircle/>
          Still rolling
        </div>
        :
        <>
          <div className='roll-details__duration'>
            <div className='data-header'>
              Roll duration
            </div>
            <div className='data-values'>
              <b>{readableSeconds(r_timestamp - timestamp, false)}</b>
            </div>
          </div>
          <div className='roll-details__status'>
            <div className='data-header'>
              Result
            </div>
            {status === 3 ?
              <div className='data-values'>
                Unresolved
              </div>
              :
              <div className={`data-values ${statColor(roll)}`}>
                {result} {resultText(roll)}
              </div>
            }
          </div>
          <div className='roll-details__text'>
          </div>
        </>
      }


      <div className='roll-details__hashes'>
        <div>
          Tx: <a href={`https://etherscan.io/tx/${hash}`} target="_blank" rel="noopener noreferrer">{hash}</a>
        </div>
        <div>
          Adr: <a href={`https://etherscan.io/address/${user}`} target="_blank" rel="noopener noreferrer">{user}</a>
        </div>
      </div>
    </div>
  )
};


const mapStateToProps = state => {
  return {
    getRollFromId: rollId => getRollFromId(state, rollId),
    currency: getCurrency(state),
  }
};

export default connect(mapStateToProps)(RollDetails);