import axios from "axios/index";
import {
  DICE_TOKEN_CONTRACT,
  LIQUIDITY_CONTRACT_ADDRESS,
  LIQUIDITY_CONTRACT_OWNERS
} from "../util/liquidity-contract/liquidity_contract";


const URL = process.env.REACT_APP_API_HOST + '/event-logs/:action/:address/:startblock';

const COMMON_PARAMS = {
  module: 'account',
  address: LIQUIDITY_CONTRACT_ADDRESS,
  endblock: 99999999,
  sort: 'asc',
};

export async function fetchTrades(){

  const transactions = await fetchLiquidityContractTransactions();
  const internalTransactions = await fetchLiquidityContractInternalTransactions();
  const tokenTransfers = await fetchLiquidityContractTokenTransfers();

  return processTrades(transactions, internalTransactions, tokenTransfers);

}

async function fetchLiquidityContractTransactions(){

  const params = {
    ...COMMON_PARAMS,
    startblock: 5965217,
    action: 'txlist',
  };

  const url = URL
      .replace(':action','txlist')
      .replace(':address', LIQUIDITY_CONTRACT_ADDRESS)
      .replace(':startblock',5965217);

  try {
    const response = await axios.get(url, { params });
    return response.data.result
  } catch (error) {
    throw new Error(error)
  }

}

async function fetchLiquidityContractInternalTransactions(){

  const params = {
    ...COMMON_PARAMS,
    startblock: 5967336,
    action: 'txlistinternal',
  };

  const url = URL
      .replace(':action','txlistinternal')
      .replace(':address', LIQUIDITY_CONTRACT_ADDRESS)
      .replace(':startblock',5967336);

  try {
    const response = await axios.get(url, { params });
    return response.data.result
  } catch (error) {
    throw new Error(error)
  }
}

async function fetchLiquidityContractTokenTransfers(){

  const params = {
    ...COMMON_PARAMS,
    startblock: 5965346,
    action: 'tokentx',
  };

  const url = URL
      .replace(':action','tokentx')
      .replace(':address', LIQUIDITY_CONTRACT_ADDRESS)
      .replace(':startblock',5965346);

  try {
    const response = await axios.get(url, { params });
    return response.data.result
  } catch (error) {
    throw new Error(error)
  }

}

function processTrades(rawTransactions, rawInternalTransactions, rawTokenTransfers){

  let transactions = {};
  let tokenTransfers = {};
  let internalTransactions = {};

  // index transactions
  rawTransactions.forEach(transaction=>{
    if(transaction.isError === "0"){
      let block = transactions[transaction.blockNumber];
      if(!block){
        block = {};
        transactions[transaction.blockNumber] = block;
      }
      block[transaction.transactionIndex] = transaction;
    }
  });


  // index token transfers
  rawTokenTransfers.forEach(rawTokenTransfer=>{
    if(rawTokenTransfer.contractAddress===DICE_TOKEN_CONTRACT &&  !LIQUIDITY_CONTRACT_OWNERS.includes(rawTokenTransfer.to)){
      let block = tokenTransfers[rawTokenTransfer.blockNumber];
      if(!block){
        block = {};
        tokenTransfers[rawTokenTransfer.blockNumber] = block;
      }
      block[rawTokenTransfer.transactionIndex] = rawTokenTransfer;
    }
  });
  // index internal transactions
  rawInternalTransactions.forEach(internalTransaction=>{
    let block = internalTransactions[internalTransaction.blockNumber];
    if(!block){
      block = {};
      internalTransactions[internalTransaction.blockNumber] = block;
    }
    block[internalTransaction.to] = internalTransaction;
  });

  const trades = [];
  Object.keys(transactions).forEach(blockNumber=>{
    let block = transactions[blockNumber];
    Object.keys(block).forEach(idx=>{
      let transaction = transactions[blockNumber][idx];
      let tokenTransfer = tokenTransfers[blockNumber] ? tokenTransfers[blockNumber][idx] : null;
      let internalTransaction = internalTransactions[blockNumber] ? internalTransactions[blockNumber][transaction.from] : null;
      if(tokenTransfer){
        trades.push({
          seller: tokenTransfer.from
          , buyer: tokenTransfer.to
          , eth: (internalTransaction ? internalTransaction.value : transaction.value) / 1000000000000000000
          , dice: tokenTransfer.value / 10000000000000000
          , timeStamp: transaction.timeStamp
          , hash: transaction.hash
        });
      }
    });
  });

  return trades.reverse()

}