import { combineReducers } from 'redux';

import statusReducer, {NAME_SPACE as status} from './status';
import ratesReducer, {NAME_SPACE as rates} from './rates';
import holdingsReducer, {NAME_SPACE as holdings} from './holdings';
import rateChangeReducer, {NAME_SPACE as rateChange} from './rateChange';
import rollsReducer, {NAME_SPACE as rolls} from './rolls';
import popupReducer, {NAME_SPACE as popup} from './popup';
import currencyReducer, {NAME_SPACE as currency} from './currency';
import statisticsReducer, {NAME_SPACE as stats} from './stats';
import topPlayersReducer, {NAME_SPACE as topPlayers} from './topPlayers';
import liquidityContractReducer, {NAME_SPACE as liquidityContract} from './liquidityContract';
import trollBoxReducer, {NAME_SPACE as trollBox} from './trollBox';
import notificationsReducer, {NAME_SPACE as notifications} from './notifications';

export default combineReducers({
  [status]: statusReducer,
  [rates]: ratesReducer,
  [holdings]: holdingsReducer,
  [rateChange]: rateChangeReducer,
  [rolls]: rollsReducer,
  [popup]: popupReducer,
  [currency]: currencyReducer,
  [stats]: statisticsReducer,
  [topPlayers]: topPlayersReducer,
  [liquidityContract]: liquidityContractReducer,
  [trollBox]: trollBoxReducer,
  [notifications]: notificationsReducer,
});