import React, {useState} from 'react';

import './transaction-status.scss';
import {getWeb3, hasWeb3} from "../../../util/web3";
import Timer from "../../util/Timer";
import LoaderCircle from "../../util/LoaderCircle";
import NewTab from "mdi-react/OpenInNewIcon";
import CheckIcon from "mdi-react/CheckboxMarkedCircleOutlineIcon";


const checkTransactionStatus = (hash, setTransaction) => {
    if(hasWeb3()){
        const web3 = getWeb3();
        web3.eth.getTransactionReceipt(hash, (error, result)=>{
            setTransaction(result);
        })
    }
};

const TransactionStatus = ({hash}) => {
    const [transaction, setTransaction] = useState(undefined);
    return (
        <div id='transaction-status'>
            {!transaction &&
                <>
                    <Timer
                        action={()=>checkTransactionStatus(hash, setTransaction)}
                        interval={2.5}
                    />
                    <h1>
                        Please Wait
                    </h1>
                    <h4>
                        mining transaction
                    </h4>
                    <LoaderCircle />
                </>
            }
            {transaction &&
                <>
                    <h1>
                        Transaction Mined
                    </h1>
                    <CheckIcon className='check-icon' />
                </>
            }
            <div className='hash'>
                {hash}
            </div>
            <a
                href={`https://etherscan.io/tx/${hash}`}
                target="_blank"
                rel="noopener noreferrer"
                title="View transaction on Etherscan.io"
            >
                Check on Etherscan
                <NewTab />
            </a>
        </div>
    )
};

export default TransactionStatus;