export const NAME_SPACE = 'popup';

const ACTIVATE_POPUP = `${NAME_SPACE}/ACTIVATE_POPUP`;
const CLOSE_POPUP = `${NAME_SPACE}/CLOSE_POPUP`;


export function activatePopup(content) {
    return {
        type: ACTIVATE_POPUP,
        content: content,
    };
}

export function closePopup() {
    return {
        type: CLOSE_POPUP,
    };
}

const initialState = {
    active: false,
    content: undefined
};


function popup(state = initialState, action) {
    switch (action.type) {
        case ACTIVATE_POPUP:
            return {
                ...state,
                active: true,
                content: action.content
            };
        case CLOSE_POPUP:
            return {
                ...state,
                active: false,
            };
        default:
            return state
    }
}


export function getState(state) {
    return state[NAME_SPACE];
}

export function getPopupContent(state) {
    return getState(state).content;
}

export function isPopupActive(state) {
    return getState(state).active;
}


export default popup;