import React from 'react';
import {Provider} from 'react-redux';
import {HashRouter as Router, Route, Switch, withRouter} from 'react-router-dom';

import './sass/App.scss';
import Header from "./components/layout/Header";
import NavBar from "./components/layout/NavBar";
import TabCurrent from "./components/tab-status/TabStatus";
import Footer from "./components/layout/Footer";
import GlobalTimers from "./components/util/GlobalTimers";
import TabRolls from "./components/tab-rolls/TabRolls";
import TabStats from "./components/tab-stats/TabStats";
import TabContracts from "./components/tab-contracts/TabContracts";
import TabDividends from "./components/tab-dividends/TabDividends";
import TabTopPlayers from "./components/tab-top-players/TabTopPlayers";
import TabEstimate from "./components/tab-estimate/TabEstimate";
import LiquidityContract from "./components/tab-exchanges/liquidity-contract/LiquidityContract";
import TabExchanges from "./components/tab-exchanges/TabExchanges";
import TabTrollBox from "./components/tab-troll-box/TabTrollBox";
import Popup from "./components/util/Popup";

/*
const TransitionWrapper = withRouter(({ location }) => {
  return (
    <TransitionGroup className="transition-group">
      <CSSTransition
        key={location.pathname}
        timeout={{ enter: 300, exit: 300 }}
        classNames="transition-slide"
      >
        <>
          <Switch location={location}>
            <Route path="/rolls" component={TabRolls} />
            <Route path="/" component={TabCurrent} />
          </Switch>
        </>
      </CSSTransition>
    </TransitionGroup>
  )
});
*/

const TransitionWrapper = withRouter(({location}) => {
    return (
        <Switch location={location}>
            <Route path="/rolls/:player?" component={TabRolls}/>
            <Route path="/stats" component={TabStats}/>
            <Route path="/contracts" component={TabContracts}/>
            <Route path="/estimate" component={TabEstimate}/>
            <Route path="/dividends" component={TabDividends}/>
            <Route path="/top-players/:metric?/:period?" component={TabTopPlayers}/>
            <Route path="/exchanges" component={TabExchanges}/>
            <Route path="/liquidity-contract" component={LiquidityContract}/>
            <Route path="/troll-box" component={TabTrollBox}/>
            <Route path="/" component={TabCurrent}/>
        </Switch>
    )
});

export default function App({store}) {
    return (
        <Provider store={store}>
            <Router hashType='slash'>
                <div id="App">
                    <GlobalTimers/>
                    <Header/>
                    <NavBar/>
                    <main>
                        <TransitionWrapper/>
                    </main>
                    <Footer/>
                    <Popup/>
                </div>
            </Router>
        </Provider>
    );
};
