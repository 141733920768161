import React from 'react';
import {TOP_PLAYERS_PERIODS} from "../../ducks/topPlayers";

import './periods.scss';
import {Link} from "react-router-dom";

const Periods = ({period: selectedPeriod, metric}) => (
    <div className='periods'>
        {Object.keys(TOP_PLAYERS_PERIODS).map(period =>
            <Link
                key={period}
                className={`periods__period ${period === selectedPeriod && 'selected'}`}
                to={`/top-players/${metric}/${period}`}
            >
                {period.toUpperCase()}
            </Link>
        )}
    </div>
);


export default Periods;