import React from 'react';
import './tab-exchanges.scss';
import LinkIcon from "mdi-react/LinkIcon";
import SeeIcon from "mdi-react/ArrowRightCircleIcon";
import {Link} from "react-router-dom";

export const EXCHANGES = [
  // {
  //   name: 'Uniswap10x',
  //   url: 'https://uniswap10x.exchange/swap?inputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&outputCurrency=0x2e071D2966Aa7D8dECB1005885bA1977D6038A65',
  //   readableUrl: 'uniswap10x.exchange',
  //   description: 'Uniswap10x is a fork of the regular Uniswap service. Just search for the DICE/ROL-token and trade away. Requires some knowledge of how to use Ethereum, for example with MetaMask.'
  // },
  {
    name: 'Uniswap10x',
    url: '/swap?inputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2&outputCurrency=0x2e071D2966Aa7D8dECB1005885bA1977D6038A65',
    readableUrl: 'uniswap10x.exchange',
    description: 'Uniswap10x is a fork of the regular Uniswap service. Just search for the DICE/ROL-token and trade away. Requires some knowledge of how to use Ethereum, for example with MetaMask.'
  },
  {
    name: 'HitBTC',
    url: 'https://hitbtc.com/DICE-to-ETH',
    readableUrl: 'hitbtc.com',
    description:
      <div>
        Traditional, centralized crypto exchange. Probably the easiest alternative.
        <br/>
        <b>Warning:</b> High withdrawal fees (28.5 DICE) and suspicious trading activity.
      </div>
  },
  {
    name: 'ForkDelta',
    url: 'https://forkdelta.app/#!/trade/DICE-ETH',
    readableUrl: 'forkdelta.app',
    description: 'Decentralised exchange for ERC20 (Ethereum) tokens, forked from Etherdelta. Requires some knowledge of how to use Ethereum, for example with MetaMask.'
  },
  /*
  ,{
      name: 'IDEX'
    , url: 'https://idex.market/eth/dice'
    , description: 'Decentralised exchange for ERC20 (Ethereum) tokens. Requires some knowledge of how to use Ethereum, for example with MetaMask.'
  }
  */
  {
    name: 'DDEX',
    url: 'https://ddex.io/trade/DICE-WETH',
    readableUrl: 'ddex.io',
    description: 'non-custodial decentralised exchange for ERC20 (Ethereum) tokens. Built on Hydro Protocol technology, offering instant, real-time order matching with secure on-chain settlement. Requires some knowledge of how to use Ethereum, for example with MetaMask. '
  },
  // {
  //   name: 'DEXY',
  //   url: 'https://app.dexy.exchange/#/exchange/DICE',
  //   readableUrl: 'app.dexy.exchange',
  //   description: 'Decentralised exchange for ERC20 (Ethereum) tokens. Requires some knowledge of how to use Ethereum, for example with MetaMask.'
  // },
  {
    name: 'EtherDelta',
    url: 'https://etherdelta.com',
    readableUrl: 'etherdelta.com',
    description: <>The original decentralised exchange for ERC20 (Ethereum) tokens. Was sold and dropped in popularity. Still functional. Requires some knowledge of how to use Ethereum, for example with MetaMask.<br /><br /><strong>Currently closed</strong></>
  },
  {
    name: 'DICE Token Liquidity Contract',
    url: '#liquidity-contract',
    readableUrl: '#liquidity-contract',
    description: <>Community made smart contract. Great for small amounts (1 ETH and less)! <br /><br /><strong>Currently closed</strong></>,
  },
];

class TabExchanges extends React.Component {

  render() {
    return (
      <div id='tab-exchanges'>
        <h1 className="header">
          Where to buy and sell DICE tokens
        </h1>
        {EXCHANGES.map(exchange => {
          return (
            <div className="exchange" key={exchange.name}>
              <h2>
                {exchange.name}
              </h2>
              <div className="exchange__description">
                {exchange.description}
              </div>
              <div className="exchange__url">
                {exchange.url === '#liquidity-contract' ?
                  <Link to={'liquidity-contract'}>
                    <SeeIcon />
                    Check current rates here
                  </Link>
                  :
                  <a
                    href={exchange.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkIcon />
                    {exchange.readableUrl}
                  </a>
                }
              </div>
            </div>
          )
        })}
      </div>
    )
  }

}

export default TabExchanges;