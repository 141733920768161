import React from 'react';
import {DOCUMENT_TITLE} from "../../util/misc";

class DocTitle extends React.Component {
    constructor(props){
        super(props);
        this.setPageTitle(props);
    }
    componentWillUpdate(nextProps) {
        this.setPageTitle(nextProps);
    }
    setPageTitle = ({text}) => {
        document.title = text ? `${text} - ${DOCUMENT_TITLE}` : DOCUMENT_TITLE;
    };
    render(){
        return null;
    }
}

export default DocTitle;