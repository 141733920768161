import {fetchRolls} from "../services/rollsAPI";
import {getMedian} from "../util/misc";
export const NAME_SPACE = 'rolls';

const LOAD_ROLLS_PENDING = `${NAME_SPACE}/LOAD_ROLLS_PENDING`;
const LOAD_ROLLS_FAIL= `${NAME_SPACE}/LOAD_ROLLS_FAIL`;
const LOAD_ROLLS_SUCCESS = `${NAME_SPACE}/LOAD_ROLLS_SUCCESS`;

function loadRollsPending(player) {
  return {
    type: LOAD_ROLLS_PENDING,
    player: player,
  }
}

function loadRollsFail(error) {
  return {
    type: LOAD_ROLLS_FAIL,
    error: error,
  }

}

function loadRollsSuccess(data) {
  return {
    type: LOAD_ROLLS_SUCCESS,
    rolls: data.rolls,
    stats: data.stats,
  }
}

export function loadRolls(player = undefined) {
  return function (dispatch) {
    dispatch(loadRollsPending(player));
    return fetchRolls(player).then(
      response => dispatch(loadRollsSuccess(response)),
      error => dispatch(loadRollsFail(error)),
    )
  }
}


const initialState = {
  isLoading: false,
  isDataLoaded: false,
  error: undefined,
  rolls: {},
  rollIds: [],
  newRollsCutoffId: 0,
  player: undefined,
  stats: undefined,
};

function rollsReducer(state = initialState, action) {
  switch (action.type) {
    case LOAD_ROLLS_PENDING:
      return {
        ...state,
        isLoading: true,
        player: action.player,
        rolls: action.player !== state.player ? initialState.rolls : state.rolls,
        rollIds: action.player !== state.player ? initialState.rollIds : state.rollIds,
        isDataLoaded: action.player !== state.player ? initialState.isDataLoaded : state.isDataLoaded,
      };
    case LOAD_ROLLS_FAIL:
      return {
        ...state
        , isLoading: false
        , isDataLoaded: true
        , error: action.error
      };
    case LOAD_ROLLS_SUCCESS:
      const rolls = { ...state.rolls, ...action.rolls };
      return {
        ...state
        , isLoading: false
        , isDataLoaded: true
        , rolls: rolls
        , rollIds: Object.keys(rolls)
        , newRollsCutoffId: state.rollIds.length === 0 ? 0 : Math.max(...state.rollIds)
        , stats: state.player ? action.stats : initialState.stats
      };
    default:
      return state;
  }
}

function getState(state) {
  return state[NAME_SPACE];
}

export function isDataLoaded(state){
  return getState(state).isDataLoaded && (getRollIds(state).length > 0 || getPlayerStats(state));
}

export function getRolls(state){
  return getState(state).rolls;
}

export function getRollFromId(state, id){
  return getRolls(state)[id];
}

export function getNewRollsCutoffId(state){
  return getState(state).newRollsCutoffId;
}

export function getRollIds(state){
  return getState(state).rollIds;
}

export function getPlayerStats(state){
  return getState(state).stats;
}

export function lastHourStats(state){
  const rolls = getRolls(state);
  const rollDurations = [];
  let rollCount = 0;
  let winCount = 0;
  let lossCount = 0;
  let highestWager = 0;
  let highestWagerUser = '';
  let mostWagers = 0;
  let mostWagersUser = '';
  let highestLoss = 0;
  let highestLossUser = '';
  let highestWin = 0;
  let highestWinUser = '';
  let totalWagered = 0;
  let houseProfit = 0;
  const rollsPerPlayer = {};
  Object.keys(rolls).forEach(rollId => {
    const roll = getRollFromId(state, rollId);
    const msPassed = Date.now() - (roll.timestamp * 1000);
    if(msPassed <= 1000 * 60 * 60){
      const rt = roll.r_timestamp || Math.round(Date.now() / 1000);
      const t = roll.timestamp;
      const rollDuration = rt-t;
      rollCount++;
      totalWagered += roll.stake;
      rollDurations.push(rollDuration);
      if(roll.status === 0){
        lossCount++;
        houseProfit += roll.stake;
        if(roll.stake > highestLoss){
          highestLoss = roll.stake;
          highestLossUser = roll.user;
        }
      }
      if(roll.status === 1){
        const profit = roll.payout - roll.stake;
        winCount++;
        houseProfit -= profit;
        if(profit > highestWin){
          highestWin = profit;
          highestWinUser = roll.user;
        }
      }
      if(roll.stake > highestWager){
        highestWager = roll.stake;
        highestWagerUser = roll.user;
      }
      rollsPerPlayer[roll.user] ? rollsPerPlayer[roll.user]++ : rollsPerPlayer[roll.user] = 1;
    }
  });
  Object.keys(rollsPerPlayer).forEach(player => {
    if(rollsPerPlayer[player] > mostWagers){
      mostWagers = rollsPerPlayer[player];
      mostWagersUser = player;
    }
  });
  const playerCount = Object.keys(rollsPerPlayer).length;
  const medianRollDuration = getMedian(rollDurations);
  return {
    rollCount,
    lossCount,
    winCount,
    totalWagered,
    houseProfit,
    highestWager,
    highestWagerUser,
    mostWagers,
    mostWagersUser,
    highestWin,
    highestWinUser,
    highestLoss,
    highestLossUser,
    playerCount,
    medianRollDuration,
  }
}

export default rollsReducer;