import React from "react";
import {getNewRollsCutoffId, getRolls, isDataLoaded as isDataLoadedSelector, loadRolls} from "../../ducks/rolls";
import Timer from "../util/Timer";
import Roll from "./Roll";

import CloseIcon from "mdi-react/CloseIcon";

import './tab-rolls.scss';
import LoaderHorizontal from "../util/LoaderHorizontal";
import AddressFormat from "../../util/formatters/address";
import PlayerStats from "./PlayerStats";
import HourStatsSummary from "./HourStatsSummary";
import {useDispatch, useSelector} from "react-redux";
import {activatePopup} from "../../ducks/popup";
import {Link} from "react-router-dom";
import GamePausedWarning from "../tab-status/GamePausedWarning";
import LatestTroll from "../tab-troll-box/LatestTroll";

const TabRolls = ({match}) => {

    const dispatch = useDispatch();

    const rolls = useSelector(getRolls);
    const newRollsCutoffId = useSelector(getNewRollsCutoffId);
    const isDataLoaded = useSelector(isDataLoadedSelector);

    const player = match.params.player || '';
    return (
        <div id='tab-rolls' key={player}>

            <Timer
                action={() => dispatch(loadRolls(player))}
                interval={15}
            />

            <h2>
                Live Rolls
                {player &&
                <div className={`active-player`}>
                    by
                    <Link to={`/rolls`}>
                        {AddressFormat.parseHashAddress(player)}
                        <CloseIcon/>
                    </Link>
                </div>
                }
            </h2>

            {isDataLoaded && player &&
            <PlayerStats player={player}/>
            }

            {isDataLoaded && !player &&
            <HourStatsSummary/>
            }

            <GamePausedWarning />

            <LatestTroll/>

            {!isDataLoaded &&
            <LoaderHorizontal/>
            }

            <div className='rolls'>
                {Object.keys(rolls).reverse().map(id => {
                    const roll = rolls[id];
                    // console.log(roll)
                    return (
                        <Roll
                            key={id}
                            id={id}
                            activatePopup={content=>dispatch(activatePopup(content))}
                            isNew={id > newRollsCutoffId && newRollsCutoffId !== 0}
                            {...roll}
                        />
                    )
                })}
            </div>
        </div>
    )
};

export default TabRolls;