import 'core-js/features/promise';
import 'core-js/features/array/includes';
import 'core-js/features/object/assign';
import 'web-animations-js';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {applyMiddleware, createStore} from "redux";
import thunk from 'redux-thunk';

import reducer from './ducks';

import './sass/body.scss';


const store = createStore(reducer, applyMiddleware(thunk));

ReactDOM.render(<App store={store} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
