import React from 'react';

import ClockIcon from "mdi-react/ClockOutlineIcon";
import PlayerIcon from "mdi-react/AccountIcon";

import ChanceIcon1 from "mdi-react/CircleSlice1Icon";
import ChanceIcon2 from "mdi-react/CircleSlice2Icon";
import ChanceIcon3 from "mdi-react/CircleSlice3Icon";
import ChanceIcon4 from "mdi-react/CircleSlice4Icon";
import ChanceIcon5 from "mdi-react/CircleSlice5Icon";
import ChanceIcon6 from "mdi-react/CircleSlice6Icon";
import ChanceIcon7 from "mdi-react/CircleSlice7Icon";

import './roll.scss';
import AddressFormat from "../../util/formatters/address";
import {numberFormat} from "../../util/formatters/numbers";
import TimeAgo from "./TimeAgo";
import NumberUpIcon from "mdi-react/ArrowUpIcon";
import NumberDownIcon from "mdi-react/ArrowDownIcon";
import LoaderCircle from "../util/LoaderCircle";
import RollDetails from "./RollDetails";


class Roll extends React.PureComponent {

  showRoll = () => {
    const {activatePopup, id} = this.props;
    activatePopup(
      <RollDetails rollId={id}/>
    );
  };

  render() {
    // console.log("rerender roll");
    const {
      isNew,
      status,
      result,
      limit,
      user,
      stake,
      payout,
      timestamp,
    } = this.props;
    const className = `roll ${isNew ? 'new' : 'old'}`;
    const value = status === 1 ? payout - stake : stake;
    const stakeSize = getStakeSize(value);
    const ChanceIcon = getChanceIcon(limit);
    return (
      <div className={className} onClick={this.showRoll} tabIndex={0}>
        <div className={'roll__detail roll__result'}>
          <Result
            result={result}
            limit={limit}
            status={status}
          />
          {status === null &&
          <LoaderCircle/>
          }
        </div>
        <div className={'roll__detail roll__user'}>
          <div className={'roll__user__full'}>
            <PlayerIcon/>
            {AddressFormat.parseHashAddress(user)}
          </div>
          <div className={'roll__user__abr'}>
            <PlayerIcon/>
            {AddressFormat.parseHashAddress(user, true)}
          </div>
        </div>
        <div className={'roll__detail roll__time'}>
          <ClockIcon/>
          <TimeAgo
              timestamp={timestamp * 1000}
          />
        </div>
        <div className={'roll__detail roll__chance'}>
          <ChanceIcon />
          <span className='text'>
            {100 * (limit - 1) / 100}%
          </span>
        </div>
        <div className={`roll__detail roll__stake size-${stakeSize}`}>
          {[0, 1].includes(status) &&
          <>
            {status === 1 ?
              <NumberUpIcon className={`icon_${getClassName(status, limit)}`}/> :
              <NumberDownIcon className={`icon_${getClassName(status, limit)}`}/>
            }
          </>
          }
          {numberFormat(value, getDecmials(value))}
        </div>
      </div>
    )
  }
}

const Result = ({result, limit, status}) => {
  return (
    <div className={`roll__result__${getClassName(status, limit)}`}>
      {status === null ? limit : status === 3 ? '—' : result}
    </div>
  )
};


const getClassName = (status, limit) => {
  switch (status) {
    case 0:
      return 'loss';
    case 1:
      return limit >= 5 ? 'win' : 'very-win';
    case 3:
      return 'unresolved';
    default:
      return 'rolling';
  }
};

const getStakeSize = n => {
  let stakeSize = 1;
  if (n >= 0.5) stakeSize = 2;
  if (n >= 1.0) stakeSize = 3;
  if (n >= 2.5) stakeSize = 4;
  if (n >= 5.0) stakeSize = 5;
  if (n >= 10.0) stakeSize = 6;
  if (n >= 15.0) stakeSize = 7;
  return stakeSize;
};

const getChanceIcon = limit => {
  if(limit <= 15) return ChanceIcon1;
  if(limit <= 30) return ChanceIcon2;
  if(limit <= 42) return ChanceIcon3;
  if(limit <= 57) return ChanceIcon4;
  if(limit <= 70) return ChanceIcon5;
  if(limit <= 82) return ChanceIcon6;
  return ChanceIcon7;
};

const getDecmials = n => {
  if (n >= 10) return 0;
  if (n >= 5) return 1;
  return 2;
};

export default Roll;