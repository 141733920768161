import {numberFormat} from "../../util/formatters/numbers";

export const verticalLinePlugin = {

  getLinePosition: function (chart, pointIndex) {
    const meta = chart.getDatasetMeta(0); // first dataset is used to discover X coordinate of a point
    const data = meta.data;
    const point = data[pointIndex];
    return point ? point._model.x : undefined;
  },

  renderVerticalLine: function (chartInstance, period) {

    const scale = chartInstance.scales['A'];
    const context = chartInstance.chart.ctx;

    const lineLeftOffset = this.getLinePosition(chartInstance, period.index);
    // render vertical line
    context.beginPath();
    context.strokeStyle = 'rgba(255,255,255,.25)';
    context.moveTo(lineLeftOffset, scale.top + 22);
    context.lineTo(lineLeftOffset, scale.bottom);
    context.stroke();

    // write label
    let text = period.profits >= 0 ? '+' : '-';
    text += numberFormat(Math.abs(period.profits), 0);
    if (!isMobilePortrait()) text += " eth";

    context.font = ".8em Arial";
    context.textBaseline = 'top';
    context.textAlign = 'center';
    context.fillStyle = '#ffffff';
    context.fillText(text, lineLeftOffset, scale.top + 2);

  },

  afterDatasetsDraw: function (chart, easing) {
    if (chart.options.showPeriodMarkers && chart.options.lineAtIndex && chart.data.datasets.length > 0) {
      chart.options.lineAtIndex.forEach(period => this.renderVerticalLine(chart, period));
    }
  }
};


export function isMobilePortrait() {
  return window.innerHeight > window.innerWidth
}